import {
  forwardRef,
  FormEvent,
  useCallback,
  useState,
  ChangeEvent,
  useMemo,
} from "react";
import {
  IClientGroup,
  IExercise,
  IExerciseInvite,
  IQuestion,
  ISession,
  IUser,
  Role,
  ScheduleTiming,
} from "@hulanbv/platformapp";
import { style } from "typestyle";
import { IHttpOptions } from "nest-utilities-client";
import { ReactComponent as sendIcon } from "../../../../assets/graphics/symbols/send.svg";
import { ReactComponent as scheduleIcon } from "../../../../assets/svgs/scheduled-exercise-invite.svg";
import { useAuthContext } from "../../../../state/authentication/authentication.context";
import { userService } from "../../../../state/user/user.service";
import { Button } from "../../../elements/button.element";
import { InputMultiSelectModel } from "../../../elements/input-multi-select-model.element";
import { Row } from "../../../elements/row.element";
import { TextArea } from "../../../elements/text-area.element";
import { IFormProps, Form } from "../../../elements/form.element";
import { dictionary } from "../../../../state/common/constants/dictionary.constants";
import { InputSelect } from "../../../elements/input-select.element";
import { ScheduleTimingLabels } from "../../../../state/common/constants/schedule-timing-labels.constants";
import { Checkbox } from "../../../elements/checkbox.element";
import { clientGroupService } from "../../../../state/client-group/client-group.service";
import { InputDate } from "../../../elements/input-date.element";
import { Flex } from "../../../elements/flex.element";
import { numberToOrdinal } from "../../../../utils/number-to-ordinal.utils";
import { formDataToObject } from "../../../../utils/form-data-to-object.utils";
import { dateToWeekday } from "../../../../utils/date-to-weekday.utils";
import { Input } from "../../../elements/input.element";
import { CustomizeQuestionnaireFormTemplate } from "../../customize-questionnaire-form-template";
import { createExerciseWithQuestionnaire } from "../../../../utils/create-exercise-with-questionnaire.utils";

export interface IExerciseInvitesForm extends Omit<IFormProps, "onSubmit"> {
  showGroupSelect?: boolean;
  exercise?: IExercise;
  exerciseSession?: ISession;
  onSubmit: (
    exerciseInvite: Partial<IExerciseInvite>,
    inviteeIds: string[],
    exercise?: IExercise | null,
  ) => void | Promise<void>;
}

export const ExerciseInvitesForm = forwardRef<
  HTMLFormElement,
  IExerciseInvitesForm
>((props, forwardRef) => {
  const [inviteeIds, setInviteeIds] = useState<string[]>([]);
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [selectedQuestions, setSelectedQuestions] = useState<IQuestion[]>([]);
  const [isCustomizingAssessment, setIsCustomizingAssessment] = useState(false);
  const [createdExercise, setCreatedExercise] = useState<IExercise | null>(
    null,
  );
  const [hasMultipleScheduleTimesADay, setHasMultipleScheduleTimesADay] =
    useState(false);
  const [shouldShowScheduleTiming, setShouldShowScheduleTiming] =
    useState(false);

  const { session } = useAuthContext();

  const minimalInputDate = useMemo(() => {
    const minimalDate = new Date();
    if (minimalDate.getHours() >= 23) {
      minimalDate.setDate(minimalDate.getDate() + 1);
    }

    return minimalDate;
  }, []);

  const [selectedDate, setSelectedDate] = useState<Date>(minimalInputDate);

  const minimalInputhour = useMemo(() => {
    // if the selected date is today, the minimal hour is the current hour + 1
    if (selectedDate.toDateString() === new Date().toDateString()) {
      return new Date().getHours() + 1;
    }

    return 0;
  }, [selectedDate]);

  const [selectedHour, setSelectedHour] = useState<number>(minimalInputhour);
  const [selectedEndHour, setSelectedEndHour] = useState<number>(
    minimalInputhour + 1,
  );

  const onSubmit = useCallback(
    async (formData: FormData, event: FormEvent) => {
      let newExercise: IExercise | null = createdExercise;
      if (newExercise === null && isCustomizingAssessment === true) {
        newExercise = await createExerciseWithQuestionnaire(
          title,
          description,
          selectedQuestions,
          session?.userId ?? "",
        );
      }

      const exerciseInviteFormValues =
        formDataToObject<Partial<IExerciseInvite>>(formData);
      const {
        scheduleTiming: scheduleTimingFormValue,
        message,
        scheduleOccurrenceCount,
      } = exerciseInviteFormValues;

      const exerciseInvite: Partial<IExerciseInvite> = {
        message,
        scheduledDate: new Date(),
        scheduleTiming: ScheduleTiming.ONCE,
      };

      // Creating a new date instance, because the selectedDate is a reference to the
      // state and we don't want to mutate the state.
      const endDate = new Date(selectedDate);

      // If it should be scheduled, get the scheduleTiming from formValues and use the
      // scheduledDate and selectHour set in the state
      if (scheduleTimingFormValue !== undefined) {
        exerciseInvite.scheduledDate = selectedDate;
        exerciseInvite.scheduledDate.setHours(selectedHour, 0, 0, 0);
        exerciseInvite.scheduleTiming = +scheduleTimingFormValue;
      }

      // If it should be scheduled multiple times a day, get the
      // scheduleOccurrenceCount from formValues and use the scheduledEndDate and
      // selectedEndHour set in the state
      if (hasMultipleScheduleTimesADay !== false) {
        exerciseInvite.scheduledEndDate = endDate;
        exerciseInvite.scheduledEndDate.setHours(selectedEndHour, 0, 0, 0);
        exerciseInvite.scheduleOccurrenceCount = scheduleOccurrenceCount;
      }

      await props.onSubmit(exerciseInvite, inviteeIds, newExercise);
    },
    [
      createdExercise,
      isCustomizingAssessment,
      selectedDate,
      hasMultipleScheduleTimesADay,
      props,
      inviteeIds,
      title,
      description,
      selectedQuestions,
      session?.userId,
      selectedHour,
      selectedEndHour,
    ],
  );

  const memoizedHttpGroupOptions = useMemo(
    (): IHttpOptions<IClientGroup> => ({
      match: { ownerId: session?.userId },
      select: ["name", "clientIds"],
    }),
    [session?.userId],
  );

  const memoizedHttpClientOptions = useMemo(
    () => ({
      match: { role: Role.USER, practitionerId: session?.userId },
      addFields: {
        fullName: {
          $concat: ["$name", " ", "$lastName"],
        },
      },
    }),
    [session?.userId],
  );

  const handleQuestionSelect = useCallback(
    (question: IQuestion) => {
      if (selectedQuestions.includes(question)) {
        setSelectedQuestions((prevQuestions) =>
          prevQuestions.filter(
            (prevQuestion) => prevQuestion.id !== question.id,
          ),
        );
        return;
      }
      setSelectedQuestions((prevQuestions) => [...prevQuestions, question]);
    },
    [selectedQuestions],
  );

  const handleOnChangeDate = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const dateValue = event.currentTarget.valueAsDate;
      if (dateValue === null) {
        return;
      }

      const currentDate = new Date();
      if (dateValue?.toDateString() === currentDate.toDateString()) {
        setSelectedHour(currentDate.getHours() + 1);
        setSelectedEndHour(currentDate.getHours() + 2);
      } else {
        setSelectedHour(8);
        setSelectedEndHour(9);
      }

      setSelectedDate(dateValue);
    },
    [],
  );

  const handleOnChangeHour = useCallback(
    (value: string) => {
      if (!Number.isNaN(value)) {
        const hour = +value;
        setSelectedHour(hour);
        if (hour > selectedEndHour) {
          setSelectedEndHour(hour + 1);
        }
      }
    },
    [selectedEndHour],
  );

  const handleOnChangeEndHour = useCallback((value: string) => {
    if (!Number.isNaN(value)) {
      setSelectedEndHour(+value);
    }
  }, []);

  const handleOnToggleRecurrence = useCallback(() => {
    setShouldShowScheduleTiming(!shouldShowScheduleTiming);
  }, [shouldShowScheduleTiming]);

  const formatHour = useCallback(
    (hour: number) => `${hour < 10 ? `0${hour}` : hour}:00`,
    [],
  );

  const maxTimesADay = useMemo(
    () => (selectedEndHour - selectedHour) * 4 + 1,
    [selectedEndHour, selectedHour],
  );

  const memoizedHourOptions = useMemo(
    () =>
      Array.from(Array(23 - minimalInputhour + 1)).map((x, index) => {
        const hour = minimalInputhour + index;
        return {
          value: hour.toString(),
          label: formatHour(hour),
        };
      }),
    [formatHour, minimalInputhour],
  );

  const memoizedEndHourOptions = useMemo(() => {
    const endHourOptions = Array.from(Array(23 - selectedHour)).map(
      (x, index) => {
        const hour = selectedHour + index;
        // Skip first hour, because it's the same as the selectedHour
        const nextHour = hour + 1;
        return {
          value: nextHour.toString(),
          label: formatHour(nextHour),
        };
      },
    );

    return endHourOptions;
  }, [formatHour, selectedHour]);

  /**
   * Returns schedule timing select options with user-friendly labels based on selectedHour and selectedDate
   */
  const memoizedScheduleTimingOptions = useMemo(() => {
    const formattedHour = formatHour(selectedHour);
    const ordinalDay = numberToOrdinal(selectedDate.getDate());
    const weekDay = dateToWeekday(selectedDate);

    return [
      {
        value: ScheduleTiming.ONCE.toString(),
        label: ScheduleTimingLabels[ScheduleTiming.ONCE],
      },
      {
        value: ScheduleTiming.DAILY.toString(),
        label: hasMultipleScheduleTimesADay
          ? dictionary.literals.daily
          : dictionary.texts.scheduleTimingDailySelectOption(formattedHour),
      },
      {
        value: ScheduleTiming.WEEKLY.toString(),
        label: dictionary.texts.scheduleTimingWeeklySelectOption(weekDay),
      },
      {
        value: ScheduleTiming.MONTHLY.toString(),
        label: dictionary.texts.scheduleTimingMonthlySelectOption(ordinalDay),
      },
    ];
  }, [formatHour, selectedHour, selectedDate, hasMultipleScheduleTimesADay]);

  const handleOnChangeClients = useCallback((clients: IUser[]) => {
    const inviteeIds = clients.map((client) => client.id);
    setInviteeIds(inviteeIds);
  }, []);

  const handleOnChangeGroups = useCallback((selectedGroups: IClientGroup[]) => {
    // get all the clientIds from the selected groups and flatten the array
    const inviteeIds = selectedGroups.map((group) => group.clientIds).flat();
    // make sure all the values are unique
    const uniqueInviteeIds = Array.from(new Set(inviteeIds));
    setInviteeIds(uniqueInviteeIds);
  }, []);

  return (
    <Form {...(props.attributes, { onSubmit })} ref={forwardRef}>
      {!props.showGroupSelect && (
        <Row label={dictionary.literals.clients}>
          <InputMultiSelectModel<IUser & { fullName?: string }>
            attributes={{
              className: styles.multiSelect,
            }}
            limit={25}
            service={userService}
            httpOptions={memoizedHttpClientOptions}
            formatOption={(value) =>
              value.fullName || `${value.name} ${value.lastName}`
            }
            onChange={handleOnChangeClients}
            searchKeys={["fullName", "email"]}
            emptyPlaceholder={dictionary.literals.selectClients}
            required
          />
        </Row>
      )}
      {props.showGroupSelect && (
        <Row label={dictionary.literals.groups}>
          <InputMultiSelectModel<IClientGroup>
            attributes={{
              className: styles.multiSelect,
            }}
            limit={25}
            service={clientGroupService}
            httpOptions={memoizedHttpGroupOptions}
            formatOption={(value) => value.name}
            onChange={handleOnChangeGroups}
            searchKeys={["name"]}
            emptyPlaceholder={dictionary.literals.selectGroups}
            required
          />
        </Row>
      )}
      <Row label={dictionary.literals.message}>
        <TextArea
          attributes={{
            name: "message",
            placeholder: dictionary.texts.typeMessage,
            rows: 10,
          }}
        />
      </Row>

      {props.exercise?.questionnaireId !== undefined && (
        <Row>
          <Checkbox
            label={dictionary.literals.customizeAssessment}
            attributes={{
              onChange: setIsCustomizingAssessment,
            }}
          />
        </Row>
      )}
      {/** Customize questionnaire form template */}
      {isCustomizingAssessment && (
        <CustomizeQuestionnaireFormTemplate
          exercise={props.exercise}
          title={title}
          description={description}
          selectedQuestions={selectedQuestions}
          onQuestionSelect={handleQuestionSelect}
          onTitleChange={setTitle}
          onDescriptionChange={setDescription}
          onExerciseCreated={setCreatedExercise}
        />
      )}

      <Row>
        <Checkbox
          label={dictionary.texts.scheduleExerciseInviteToggleLabel}
          attributes={{
            onChange: handleOnToggleRecurrence,
          }}
        />
      </Row>
      {/** Scheduling inputs */}
      {shouldShowScheduleTiming && (
        <>
          <Row label={dictionary.literals.chooseADateAndTime}>
            <Flex gap={5}>
              <InputDate
                attributes={{
                  required: true,
                  onChange: handleOnChangeDate,
                  min: minimalInputDate.toISOString().split("T")[0],
                  defaultValue: selectedDate.toISOString().substring(0, 10),
                }}
                containerClassName={styles.beginDateTimeSelect}
              />
              <InputSelect
                isRequired
                className={styles.beginDateTimeSelect}
                value={selectedHour.toString()}
                placeholder={dictionary.literals.selectHour}
                onChange={handleOnChangeHour}
                options={memoizedHourOptions}
              />
            </Flex>
          </Row>
          <Row>
            <Checkbox
              label={dictionary.literals.multipleTimesADay}
              attributes={{
                onChange: setHasMultipleScheduleTimesADay,
              }}
            />
          </Row>
          {hasMultipleScheduleTimesADay !== false && (
            <Row>
              <Flex gap={10} justifyContent="space-between">
                <Input
                  attributes={{
                    type: "number",
                    name: "scheduleOccurrenceCount",
                    min: 1,
                    max: maxTimesADay,
                    defaultValue: 1,
                  }}
                  containerClassName={styles.numberInput}
                />
                <p className={styles.description}>
                  {dictionary.texts.timesADayDescription(
                    formatHour(selectedHour),
                  )}
                </p>
                <InputSelect
                  isRequired
                  value={selectedEndHour.toString()}
                  className={styles.endDateTimeSelect}
                  placeholder={dictionary.literals.selectHour}
                  onChange={handleOnChangeEndHour}
                  options={memoizedEndHourOptions}
                />
              </Flex>
            </Row>
          )}
          <Row label={dictionary.literals.recurrence}>
            <InputSelect
              isRequired
              name="scheduleTiming"
              defaultValue={ScheduleTiming.ONCE.toString()}
              placeholder={dictionary.literals.selectRecurrence}
              options={memoizedScheduleTimingOptions}
            />
          </Row>
        </>
      )}
      <div className={styles.buttonContainer}>
        {!shouldShowScheduleTiming && (
          <Button
            hideSpinnerOnSubmit
            attributes={{
              type: "submit",
              disabled:
                isCustomizingAssessment && selectedQuestions.length === 0,
            }}
            icon={sendIcon}
          >
            {dictionary.literals.send}
          </Button>
        )}
        {shouldShowScheduleTiming && (
          <Button
            hideSpinnerOnSubmit
            attributes={{
              type: "submit",
              disabled:
                isCustomizingAssessment && selectedQuestions.length === 0,
            }}
            icon={scheduleIcon}
          >
            {dictionary.literals.schedule}
          </Button>
        )}
      </div>
    </Form>
  );
});

const styles = {
  buttonContainer: style({
    display: "flex",
    flexDirection: "column",
    gap: "var(--spacing-vertical-small)",
  }),
  multiSelect: style({
    marginBottom: 10,
  }),
  beginDateTimeSelect: style({
    flex: 1,
  }),
  numberInput: style({
    flex: 1,
  }),
  endDateTimeSelect: style({
    flex: 2,
  }),
  description: style({
    flex: 2,
    textAlign: "center",
    margin: 0,
  }),
};

import { FC, useEffect, useMemo, useState } from "react";
import { keyframes, style } from "typestyle";

// Define the props interface for the BarChart component
interface IProps {
  xLabels?: string[];
  yLabels?: string[];
  data: {
    color: string;
    label: string;
    values: number[];
  }[];
}

// Define the BarChart component
export const BarChart: FC<IProps> = (props) => {
  // State to control animation
  const [isAnimating, setIsAnimating] = useState(true);

  // Calculate the increment for the y-axis labels
  const incrementor = useMemo(() => {
    const highestNumber = Math.max(
      ...props.data.reduce<number[]>(
        (previous, current) => previous.concat(current.values),
        [],
      ),
    );

    const highestRounded = Math.ceil(highestNumber / 10) * 10;
    const increment = Math.ceil(highestRounded / 5 / 10) * 10;

    return increment;
  }, [props.data]);

  // Calculate the maximum length of the x-axis labels
  const maxValueLength = useMemo(
    () => Math.max(...props.data.map((data) => data.values.length)),
    [props.data],
  );

  // Generate the x-axis labels
  const xAxisLabels: string[] = useMemo(() => {
    if (props.xLabels !== undefined) {
      return props.xLabels;
    }

    return Array(maxValueLength)
      .fill(0)
      .map((x, index) => `${index + 1}`);
  }, [maxValueLength, props.xLabels]);

  // Generate the y-axis labels
  const yAxisLabels: (number | string)[] = useMemo(() => {
    if (props.yLabels !== undefined) {
      return props.yLabels;
    }

    return Array(6)
      .fill(0)
      .map((x, index) => index * (incrementor || 20));
  }, [incrementor, props.yLabels]);

  // Disable animation after component is mounted
  useEffect(() => {
    window.setTimeout(() => {
      setIsAnimating(false);
    }, 100);
  }, []);

  // Render the BarChart component
  return (
    <svg viewBox={`0 0 ${width} ${svgHeight}`} className={styles.graph}>
      {/* Render y-axis grid lines */}
      {yAxisLabels.map((y, index) => (
        <polyline
          fill="none"
          stroke="rgba(var(--rgb-color-gray),0.5)"
          strokeWidth="1"
          key={index}
          points={`${padding}, ${
            height -
            index * ((height - padding) / (yAxisLabels.length ?? 0)) -
            padding
          } ${width - padding + 30}, ${
            height -
            index * ((height - padding) / (yAxisLabels.length ?? 0)) -
            padding
          }`}
        />
      ))}

      {/* Render x-axis labels */}
      {xAxisLabels.map((x, index) => (
        <text
          x={
            index * ((width - padding * 2) / (maxValueLength - 1)) +
            (padding + 10)
          }
          y={height - padding + 10}
          key={index}
          dominantBaseline="middle"
          fontSize="12"
          fill="rgb(var(--rgb-color-black))"
          transform={`rotate(40, ${
            index * ((width - padding * 2) / (maxValueLength - 1)) +
            padding +
            10
          }, ${height - padding + 10})`}
        >
          {x}
        </text>
      ))}

      {/* Render y-axis lines */}
      <polyline
        fill="none"
        stroke="rgba(var(--rgb-color-gray),0.5)"
        strokeWidth="2"
        points={`${padding}, ${padding} ${padding}, ${height - padding}`}
      />
      <polyline
        fill="none"
        stroke="rgba(var(--rgb-color-gray),0.5)"
        strokeWidth="1"
        points={`${width - padding + 30}, ${padding} ${width - padding + 30}, ${
          height - padding
        }`}
      />

      {/* Render y-axis labels */}
      {yAxisLabels.map((y, index) => (
        <text
          x={padding - 20}
          y={
            height -
            index * ((height - padding) / (yAxisLabels.length ?? 0)) -
            padding
          }
          key={index}
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize="12"
          fill="rgb(var(--rgb-color-black))"
        >
          {y}
        </text>
      ))}

      {/* Render bars */}
      {props.data.map((data, index) => (
        <g key={index}>
          {data.values.map((value, valueIndex) => (
            <rect
              key={valueIndex}
              x={
                valueIndex * ((width - padding * 2) / (maxValueLength - 1)) +
                (padding + 5) +
                index * 10
              }
              y={
                height -
                padding -
                (height - padding * 2) *
                  (value / Number(yAxisLabels[yAxisLabels.length - 1] ?? 0))
              }
              width={10}
              height={
                (height - padding * 2) *
                (value / Number(yAxisLabels[yAxisLabels.length - 1] ?? 0))
              }
              fill={data.color}
              className={`${styles.bar} ${!isAnimating && styles.transition}`}
            />
          ))}
        </g>
      ))}

      {/* Render legend */}
      {props.data.map((data, index) => (
        <g
          key={index}
          transform={`translate(${padding}, ${index * 25 + height})`}
        >
          <line
            x1="0"
            y1="10"
            x2="20"
            y2="10"
            stroke={data.color}
            strokeWidth="8"
          />
          <text x="30" y="15" fontSize="15">
            {data.label}
          </text>
        </g>
      ))}
    </svg>
  );
};

// Define the CSS animation for bar growth
const grow = keyframes({
  from: {
    transform: "scaleY(0)",
  },
  to: {
    transform: "scaleY(1)",
  },
});

// Define the padding and dimensions for the chart
const padding = 50;
const width = 500;
const height = 350;
const svgHeight = 400;

// Define the CSS styles for the chart
const styles = {
  graph: style({
    width: "100%",
    height: "100%",
  }),
  bar: style({
    transformOrigin: "bottom",
    transformBox: "border-box",
    animation: `${grow} 1s forwards`,
  }),
  transition: style({
    transition: "all 0.5s 0.1s",
  }),
};

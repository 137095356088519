import { FC, useCallback, useEffect, useState } from "react";
import { style } from "typestyle";
import { PbatType } from "@hulanbv/platformapp";
import { generatePath, useHistory, useParams } from "react-router";
import { dictionary } from "../../state/common/constants/dictionary.constants";
import { Header } from "../elements/header.element";
import { PageBody } from "../elements/page-body.element";
import { Page } from "../elements/page.element";
import { Description } from "../elements/description.element";
import { ReportFormTemplate } from "../templates/forms/report/report-form.template";
import { questionService } from "../../state/question/question.service";
import { routes } from "../../state/common/constants/routes.constants";

export const CreateReportScreen: FC = () => {
  const history = useHistory();
  const params = useParams<{ userId: string }>();

  const [resultIdentifiers, setResultIdentifiers] = useState<string[]>([]);
  const [hasError, setHasError] = useState<boolean>(false);

  const [processOfChangeOptions, setProcessOfChangeOptions] = useState<
    string[]
  >([]);

  useEffect(() => {
    (async () => {
      const { data: answeredIdentifierQuestions } =
        await questionService.getAnsweredQuestions(params.userId, {
          match: {
            resultIdentifier: { $exists: true },
          },
          distinct: "resultIdentifier",
          sort: ["resultIdentifier"],
        });

      const resultIdentifiers = answeredIdentifierQuestions.map(
        (question) => question.resultIdentifier ?? "",
      );

      const processOfChangeResultIdentifiers = answeredIdentifierQuestions
        .filter((question) => question.pbatType === PbatType.PROCESS_OF_CHANGE)
        .map((question) => question.resultIdentifier ?? "");

      setProcessOfChangeOptions(processOfChangeResultIdentifiers);
      setResultIdentifiers(resultIdentifiers);
    })();
  }, [params.userId]);

  const submit = useCallback(
    async (formData: FormData) => {
      const processOfChange = formData.get("processOfChange") ?? "";
      const outcome = formData.get("outcome") ?? "";

      if ((processOfChange || outcome) === "{{undefined}}") {
        setHasError(true);
        return;
      }

      const searchParams = new URLSearchParams(
        window.location.search,
      ).toString();

      history.push({
        pathname: generatePath(routes.weekResultsReport.path, {
          userId: params.userId,
          resultIdentifier1: outcome.toString(),
          resultIdentifier2: processOfChange.toString(),
        }),
        search: searchParams,
      });
    },
    [history, params.userId],
  );

  return (
    <Page>
      <PageBody>
        <Header className={styles.centerHeader}>
          {dictionary.literals.mindgrapherReport}
        </Header>
        <Description className={styles.spacing}>
          {dictionary.texts.reportDisclaimer}
        </Description>
        <ReportFormTemplate
          outcomeOptions={resultIdentifiers}
          processOfChangeOptions={processOfChangeOptions}
          onSubmit={submit}
          error={hasError}
        />
      </PageBody>
    </Page>
  );
};

const styles = {
  centerHeader: style({
    display: "flex",
    justifyContent: "center",
    paddingBottom: 20,
  }),
  spacing: style({
    marginBottom: 50,
  }),
};

import { FC, useCallback, useMemo, useState } from "react";
import { style } from "typestyle";
import { Flex } from "./flex.element";
import { FlexItem } from "./flex-item.element";
import { Button } from "./button.element";
import { ReactComponent as arrowLeftIcon } from "../../assets/graphics/symbols/expand-left.svg";
import { ReactComponent as arrowRightIcon } from "../../assets/graphics/symbols/expand-right.svg";

import { dateUtils } from "../../utils/date.utils";

interface IProps {
  defaultEndDate?: Date;
  onChange?: (targetDate: Date) => void;
}

export const WeekNavigationElement: FC<IProps> = (props) => {
  const [endDate, setEndDate] = useState<Date>(
    props.defaultEndDate ?? new Date(),
  );
  const startDate = useMemo(() => {
    const date = new Date(endDate);
    date.setDate(date.getDate() - 13);
    return date;
  }, [endDate]);

  const handleWeekNavigation = useCallback(
    (addition: number) => {
      const date = new Date(endDate);
      date.setDate(date.getDate() + addition);

      setEndDate(date);
      props.onChange?.(date);
    },
    [endDate, props],
  );

  return (
    <Flex direction="row" gap={10} padding={"20px 0px"}>
      <FlexItem>
        <Button
          flavour="primary"
          icon={arrowLeftIcon}
          attributes={{
            className: styles.icon,
            onClick: () => handleWeekNavigation(-14),
          }}
        ></Button>
      </FlexItem>
      <FlexItem>
        {dateUtils.getFormattedDate(startDate)} -{" "}
        {dateUtils.getFormattedDate(endDate)}
      </FlexItem>
      <FlexItem>
        <Button
          flavour="primary"
          icon={arrowRightIcon}
          hideSpinnerOnSubmit
          attributes={{
            className: styles.icon,
            onClick: () => handleWeekNavigation(14),
            disabled:
              endDate.toLocaleDateString() === new Date().toLocaleDateString(),
          }}
        ></Button>
      </FlexItem>
    </Flex>
  );
};

const styles = {
  icon: style({
    padding: "2px 8px",
    minHeight: 0,
    textAlign: "center",
    $nest: {
      "& svg": {
        padding: 4,
      },
    },
  }),
};

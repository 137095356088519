import {
  forwardRef,
  FormHTMLAttributes,
  PropsWithChildren,
  useMemo,
  useState,
} from "react";
import {
  IQuestion,
  IQuestionAnswer,
  IUserAnswer,
  QuestionType,
} from "@hulanbv/platformapp";
import { Button } from "../../../elements/button.element";
import { Form } from "../../../elements/form.element";
import { Row } from "../../../elements/row.element";
import { dictionary } from "../../../../state/common/constants/dictionary.constants";
import { TextArea } from "../../../elements/text-area.element";
import { RadioButton } from "../../../elements/radio-button.element";
import { useAuthContext } from "../../../../state/authentication/authentication.context";
import { ReactComponent as fastForwardIcon } from "../../../../assets/graphics/symbols/fast-forward.svg";
import { RangeSlider } from "../../../elements/range-slider.element";
import { Flex } from "../../../elements/flex.element";
import { IconButton } from "../../../elements/icon-button.element";
import { Checkbox } from "../../../elements/checkbox.element";

interface IProps {
  attributes?: Omit<FormHTMLAttributes<HTMLFormElement>, "onSubmit">;
  userAnswer?: Partial<IUserAnswer>;
  question: IQuestion;
  questionIndex: number;
  submitButtonText?: string;
  onSubmit?: (formData: FormData) => void | Promise<void>;
  onPrevious?: () => void;
}

export const UserAnswerForm = forwardRef<
  HTMLFormElement,
  PropsWithChildren<IProps>
>((props, ref) => {
  const { session } = useAuthContext();
  const [selectedUserAnswers, setSelectedUserAnswers] = useState<string[]>(
    props.userAnswer?.value?.map((value) => value.toString()) ?? [],
  );

  const toggleSelectedAnswer = (answer: IQuestionAnswer) => {
    const newSelectedUserAnswers = selectedUserAnswers.includes(answer.value)
      ? selectedUserAnswers.filter(
          (selectedAnswer) => selectedAnswer !== answer.value,
        )
      : [...selectedUserAnswers, answer.value];

    setSelectedUserAnswers(newSelectedUserAnswers);
  };

  const availableRangeLabels = useMemo(() => {
    if (
      ![QuestionType.RANGE].includes(props.question.type) &&
      !props.question.isNumericalQuestion
    ) {
      return [];
    }

    const filteredAnswersArray = props.question.answers?.filter(
      (answer) => answer.label !== undefined,
    );

    return filteredAnswersArray?.map((answer) => answer.label) ?? [];
  }, [props.question]);

  const availableAnswersInRange = useMemo(() => {
    if (
      ![QuestionType.RANGE].includes(props.question.type) &&
      !props.question.isNumericalQuestion
    ) {
      return [];
    }

    if (props.question.answers && props.question.answers?.length > 2) {
      return props.question.answers.map((answer) => answer.value);
    }

    const answers =
      props.question.answers?.map((answer) => Number(answer.value)) ?? [];

    const minRange = Math.min(...answers);
    const maxRange = Math.max(...answers);

    const availableAnswers = [];

    for (let i = minRange; i <= maxRange; i += 1) {
      availableAnswers.push(i.toString());
    }

    return availableAnswers;
  }, [props.question]);

  return (
    <Form attributes={props.attributes} onSubmit={props.onSubmit} ref={ref}>
      {props.userAnswer?._id && (
        <input type={"hidden"} name={"_id"} value={props.userAnswer._id} />
      )}
      <input
        type={"hidden"}
        name={"userId"}
        value={props.userAnswer?.userId ?? session?.userId}
      />

      <input type={"hidden"} name={"questionId"} value={props.question.id} />
      {props.question.type === QuestionType.OPEN && (
        <Row>
          <TextArea
            attributes={{
              name: "value",
              placeholder: dictionary.texts.enterAnswerPlaceholder,
              defaultValue: props.userAnswer?.value,
              required: true,
            }}
          ></TextArea>
        </Row>
      )}
      {props.question.type === QuestionType.MULTIPLE_CHOICE && (
        <Row>
          {props.question.answers?.map((answer, key) => (
            <RadioButton
              key={answer.value + key}
              attributes={{
                name: "value",
                value: answer.value,
                defaultChecked: props.userAnswer?.value?.includes(answer.value),
                required: true,
              }}
              label={answer.value}
            />
          ))}
        </Row>
      )}
      {props.question.type === QuestionType.MULTIPLE_RESPONSE && (
        <Row>
          {props.question.answers?.map((answer, key) => (
            <Checkbox
              key={answer.value + key}
              attributes={{
                isDefaultChecked: props.userAnswer?.value?.includes(
                  answer.value,
                ),
                required: true,
                onChange: () => toggleSelectedAnswer(answer),
              }}
              label={answer.value}
            />
          ))}
          {selectedUserAnswers.map((selectedAnswer, key) => (
            <input
              key={selectedAnswer + key}
              type={"hidden"}
              name={`value[${key}]`}
              value={selectedAnswer}
            />
          ))}
        </Row>
      )}
      {props.question.type === QuestionType.RANGE && props.question.answers && (
        <Row>
          <RangeSlider
            options={
              props.question.isNumericalQuestion
                ? availableAnswersInRange
                : props.question.answers.map((answer) => answer.value)
            }
            isNumericalQuestion={props.question.isNumericalQuestion}
            defaultValue={props.userAnswer?.value?.[0]}
            labels={availableRangeLabels}
            name={"value"}
          />
        </Row>
      )}
      <Flex>
        <IconButton
          icon={fastForwardIcon}
          hideSpinnerOnSubmit
          attributes={{
            style: {
              transform: "rotate(180deg)",
            },
            disabled: props.questionIndex === 0,
            onClick: props.onPrevious,
          }}
        />
        <Button
          attributes={{
            type: "submit",
            style: {
              maxHeight: 50,
            },
          }}
          icon={fastForwardIcon}
        >
          {props.submitButtonText ?? dictionary.literals.next}
        </Button>
      </Flex>
    </Form>
  );
});

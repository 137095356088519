import { environment } from "../constants/environment.constants";

export const dateUtils = new (class DateUtils {
  /**
   *
   * @param date
   * @returns days ago comparing date object to date.now
   */
  public getDaysAgo(date: Date): number {
    const diff = Math.abs(Date.now() - date.getTime());
    return Math.ceil(diff / (1000 * 3600 * 24));
  }

  /**
   *
   * @param date
   * @returns the date in dd-mm-yyyy format
   */
  public getFormattedDate(date: Date): string {
    const formattedDate = new Date(date).toLocaleDateString(
      environment.locale,
      {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      },
    );
    return formattedDate;
  }

  /**
   * Returns true if these dates are on the same day
   * @param date1
   * @param date2
   */
  public isSameDay(date1: Date, date2: Date): boolean {
    return date1.toLocaleDateString() === date2.toLocaleDateString();
  }
})();

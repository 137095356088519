/* eslint-disable eslint-comments/disable-enable-pair -- needed for translations */
/* eslint-disable max-lines -- needed for translations */
import { ReactNode } from "react";
import { branding } from "../../../constants/branding.constants";
import { environment } from "../../../constants/environment.constants";
import { dutchCountryLabels } from "../../../constants/country-labels-nl";
import { englishCountryLabels } from "../../../constants/country-labels-en";

const timeFormatter = Intl.DateTimeFormat(process.env.REACT_APP_LOCALE, {
  hour: "numeric",
  minute: "numeric",
});

const baseTranslationsEnglish = {
  literals: {
    answers: "Answers",
    login: "Login",
    submit: "Submit",
    register: "Register",
    registration: "Registration",
    password: "Password",
    email: "Email",
    forgotPassword: "Forgot password",
    moreInformation: "More information",
    backToLogin: "Back to login",
    send: "Send",
    name: "Name",
    firstName: "First name",
    lastName: "Last name",
    termsOfService: "Terms of service",
    connectClient: "Connect client",
    selectValue: "Select value",
    phoneNumber: "Phone number",
    man: "Man",
    woman: "Woman",
    other: "Other",
    chat: "Chat",
    success: "Success",
    exercise: "Exercise",
    timeline: "Timeline",
    save: "Save",
    cancel: "Cancel",
    delete: "Delete",
    deleteAccount: "Delete account",
    editLocation: "Edit location",
    setLocation: "Set location",
    removeLocation: "Remove location",
    call: "Call",
    playground: branding.productNames?.playground?.en ?? "Playground",
    eLearning: "E-learning",
    featured: "Featured",
    addToCollection: "Add to collection",
    collections: "Collections",
    edit: "Edit",
    street: "Street",
    houseNumber: "Housenumber",
    houseNumberAddition: "Housenumber addition",
    zipCode: "Zipcode",
    countryOfResidence: "Country of residence",
    companyNumber: "Company number",
    website: "Website",
    biography: "Biography",
    address: "Address",
    unknown: "Unknown",
    yes: "Yes",
    no: "No",
    confirm: "Confirm",
    view: "View",
    trainings: branding.productNames?.trainings?.en ?? "Trainings",
    freeContent: "Free content",
    session: "Session",
    sessions: "Sessions",
    exercises: "Exercises",
    message: "Message",
    groups: "Groups",
    note: "Note",
    notes: "Notes",
    pairingCode: "Pairing code",
    pair: "Pair",
    member: "Member",
    members: "Members",
    clients: "Clients",
    receivedOn: "Received on",
    viewedOn: "Viewed on",
    sentOn: "Sent on",
    filter: "Filter",
    categories: "Categories",
    labels: "Labels",
    close: "Close",
    filters: "Filters",
    licenseAgreement: "License agreement",
    error: "Error",
    item: "Item",
    items: "Items",
    selected: "Selected",
    goToGame: "Go to game",
    downloadPdf: "Download PDF",
    goToLink: "Go to link",
    previous: "Previous",
    pairPractitioner: `Pair ${
      branding.productNames?.practitioner?.en ?? "practitioner"
    }`,
    professionalLicense: "Professional license",
    typeToSearch: "Type to search",
    editPicture: "Edit picture",
    logout: "Log out",
    optionSelected: "Option selected",
    amountOfClients: "Amount of clients",
    visibleOnMap: "Visible on map",
    expirationDateLicense: "Expiration date license",
    repeatPassword: "Repeat password",
    addClient: "Add client",
    newPassword: "New password",
    requestSuccessful: "Request successful",
    noUsersSelected: "No users selected",
    selectUsers: "Select users",
    developmentEnviroment: "Development environment",
    goBack: "Go back",
    selectClients: "Select clients",
    selectGroups: "Select groups",
    newClientGroup: "New client group",
    groupName: "Group name",
    setPassword: "Set password",
    unpairClient: "Unpair client",
    selectItems: "Select items",
    noResultsFound: "No results found",
    clientsNotFound: "Clients not found",
    clientGroupsNotFound: "Client groups not found",
    practitionersNotFound: `${
      branding.productNames?.practitioners?.en ?? "Practitioners"
    } not found`,
    addClients: "Add clients",
    cityOfResidence: "City of residence",
    myPracticeEnvironment: "My practice environment",
    lookOut: "Look out",
    searchByNameAndContent: "Search by name and content",
    enterFirstName: "Enter first name",
    noContent: "No content",
    newCollection: "New collection",
    noCollectionsFound: "No collections found",
    fillInInvitedClients: "Fill in invited clients",
    editCollection: "Edit collection",
    collectionName: "Collection name",
    myExercises: "My exercises",
    onlyMyOwnExercises: "Only my own exercises",
    sentExercises: "Sent exercises",
    requestSubmitted: "Request submitted",
    showMore: "Show more",
    title: "Title",
    description: "Description",
    introduction: "Introduction",
    questionnaires:
      branding.productNames?.questionnaires?.en ?? "Questionnaires",
    selectedOption: "Selected option",
    next: "Next",
    finish: "Finish",
    completedOn: "Completed on",
    goToQuestionnaire: `Go to ${
      branding.productNames?.questionnaire?.en?.toLowerCase() ?? "questionnaire"
    }`,
    notAnsweredYet: "Not answered yet",
    schedule: "Schedule",
    chooseADateAndTime: "Choose a date and time",
    chooseATime: "Choose a time",
    once: "Once",
    multipleTimesADay: "Multiple times a day",
    daily: "Daily",
    weekly: "Weekly",
    monthly: "Monthly",
    scheduledExercises: "Scheduled exercises",
    recurrence: "Recurrence",
    selectRecurrence: "Select recurrence",
    licenseExpired: "License expired",
    getInTouch: "Get in touch",
    mindgrapher: "Mindgrapher",
    mindgrapherContent: "Mindgrapher content",
    mindgrapherReport: "Mindgrapher report",
    selectVariables: "Select variables",
    outcome: "Outcome",
    selectOutcome: "Select outcome",
    processOfChange: "Process of change",
    selectProcessOfChange: "Select process of change",
    plotTrend: "Plot trend",
    verySmallPositiveRelationship: "Very small positive relationship",
    verySmallNegativeRelationship: "Very small negative relationship",
    smallPositiveRelationship: "Small positive relationship",
    smallNegativeRelationship: "Small negative relationship",
    moderatePositiveRelationship: "Moderate positive relationship",
    moderateNegativeRelationship: "Moderate negative relationship",
    somewhatStrongPositiveRelationship: "Somewhat strong positive relationship",
    somewhatStrongNegativeRelationship: "Somewhat strong negative relationship",
    strongPositiveRelationship: "Strong positive relationship",
    strongNegativeRelationship: "Strong negative relationship",
    veryStrongPositiveRelationship: "Very strong positive relationship",
    veryStrongNegativeRelationship: "Very strong negative relationship",
    thereIsNoRelationship: "There is no relationship",
    practitioner: branding.productNames?.practitioner?.en ?? "Practitioner",
    openQuestion: "Open question",
    clearSelection: "Clear selection",
    selectHour: "Select hour",
    customizeAssessment: "Customize assessment",
  },
  texts: {
    appDescription:
      "Our app for professionals contains hundreds of animations, exercises, games and videos, which you can send unlimitedly to all your clients. In addition, you get access to live webinars and a world of video demonstrations and learning modules that will make your valuable work that much more fun. Try the app now for free for 30 days.",
    createReportError: "Fill in all the required fields",
    wrongLoginCombination: "Wrong email address/password combination",
    iAgreeToX: (subject: ReactNode) => [
      "By creating an account you agree to our ",
      subject,
    ],
    yourLicenseIsExpired: `Your license for ${
      branding.name ?? "PLAPP"
    } has expired. To renew the license please contact us here`,
    registerError:
      "Something went wrong while registering. Try a different email address.",
    forgotPasswordEmailSent:
      "If known, an email will be sent to the specified email address",
    passwordResetSuccess:
      "Your password has been successfully changed! You can now log in with your new password.",
    passwordResetError: "Something went wrong while changing your password.",
    passwordResetErrorLong:
      "Something went wrong while changing your password. Please try again or request a new password reset.",
    noExercisesReady: "There are no exercises ready for you yet.",
    collectionAddSuccess: "Successfully added to collection(s).",
    collectionAddError:
      "Something went wrong while adding the exercise to collection(s).",
    collectionSentError: "Something went wrong while sending the collection",
    retrievingClientGroupError:
      "Something went wrong while retrieving the client group.",
    addClientsToGroupSuccess: "Successfully added clients to group.",
    addClientsToGroupError:
      "Something went wrong while adding clients to group.",
    noClientsAddedToGroup: "No clients have been added to this group yet.",
    clientGroupX: (value: string) => `Clients ${value}`,
    lastOpenedOnX: (date: Date) =>
      `Last time opened on: ${date.toLocaleDateString("en-US")}`,
    usingStartLicense: "You are now using the START license.",
    masterOrProLicenseSuggestion: `Do you want to use ${
      branding.name ?? "PLAP"
    } with your clients as well? Then apply for a Master or Pro license!`,
    removeExerciseFromCollectionSuccess:
      "Successfully removed from collection.",
    removeExerciseFromCollectionError:
      "Something went wrong when removing the exercise from the collection",
    noCollectionsCreated:
      "You haven't created any collections yet. Do you want to know how to do this? Then watch the video below.",
    xExercises: (amount: number) =>
      `${amount} ${amount === 1 ? "exercise" : "exercises"}`,
    xSubCategories: (amount: number) =>
      `${amount} ${amount === 1 ? "subcategory" : "subcategories"}`,
    xSessions: (amount: number) =>
      `${amount} ${amount === 1 ? "Session" : "Sessions"}`,
    xItems: (amount: number) => `${amount} ${amount === 1 ? "item" : "items"}`,
    exerciseAddedToCollectionError:
      "Something went wrong while adding the exercise to the collection.",
    noteCreationSuccess: "Successfully created note.",
    noteCreationError: "Something went wrong while creating the note.",
    noteRemovedSuccess: "Successfully removed note.",
    noteRemovedError: "Something went wrong while removing the note.",
    noteUpdatedSuccess: "Successfully updated note.",
    noteUpdatedError: "Something went wrong while updating the note.",
    inviteSentSuccess: "Successfully sent invite.",
    inviteSentSuccessLong: "Successfully sent invite to client.",
    inviteSentErrorLong:
      "Something went wrong while sending the invitation. Make sure the client is not already paired with another professional and that you have not exceeded the maximum limit of clients!",
    unknownPairingCode:
      "Unfortunately, your pairing code is unknown, please contact the helpdesk.",
    yourPairingCodeIsX: (pairingCode: ReactNode) => [
      "Your pairing code is ",
      pairingCode,
      ". Pass this on to your client or link the client directly using the form below.",
    ],
    pairingError: "Something went wrong while pairing",
    areYouAProfessional: "Are you a professional? Click here",
    passwordResetSuccessLong:
      "Password changed successfully. The client will be informed by email.",
    setPasswordForX: (value: string) => `Set password for ${value}`,
    setOwnPassword: "Set your password",
    profileUpdatedSuccess: "Successfully updated profile.",
    profileUpdatedError: "Something went wrong while updating the profile.",
    deleteAccountPermanently: "Delete account permanently",
    deleteAccountPermanentlyDescription:
      "Enter your first name in the field below to permanently delete your account.",
    getUserError: "Something went wrong while retrieving the user.",
    locationCreateError: "Something went wrong while creating the location.",
    locationRemoveError: "Something went wrong while removing the location.",
    moreInformation: "Click here for more information",
    areYouInterested: `Are you interested in the ${
      branding.name ?? "PLAP"
    } app for your team? Then contact us without obligation.`,
    collectionInvitesSentSuccess:
      "Collection invitations have been successfully sent to the clients",
    trialDaysLeft: (days: number) => `Trial ${days.toString()} days`,
    companyNameFormal: (companyName: string) => `${companyName} formal`,
    tellSomethingAboutYourself: "Tell something about yourself",
    passwordsNotIdentical: "The passwords are not identical",
    exerciseInviteSentSuccess: "Exercises sent successfully to client(s).",
    exerciseInviteSentError: "Something went wrong sending the exercise(s).",
    collectionAddExerciseSuccess: "Successfully added to collection(s)",
    collectionAddExerciseError:
      "Something went wrong adding exercise(s) to collection(s)",
    sessionInviteSentSuccess: "Session(s) sent successfully to clients",
    sessionInviteSentError:
      "Something went wrong sending session(s) to clients",
    requestError: "Something went wrong",
    confirmLogout: "Are you sure you want to logout?",
    logoutError: "Something went wrong while logging out",
    editCollectionError: "Something went wrong while editing collection",
    addExerciseToNewOrExistingCollection:
      "Add the exercise to an existing collection or create a new one",
    typeMessage: "Type the message you want to add",
    exerciseInviteGroupError:
      "No selected groups or no clients in selected groups",
    typeYourNote: "Type your note here",
    confirmAgreement: (licenseAgreementHref: ReactNode) => [
      "By clicking here you confirm that you have read, understood and agree to the ",
      licenseAgreementHref,
    ],
    confirmNewsletterSubscription:
      "I would like to be kept informed and subscribe to the newsletter",
    confirmRegisterTrial: (trialDays: number) =>
      `Try for ${trialDays} days without obligation`,
    deleteCollection: "Remove collection",
    confirmCollectionDelete: (confirmText: string, collectionName: string) =>
      `Enter the word "${confirmText}" in the field below to permanently delete the collection ${collectionName}`,
    collectionDeleteSuccess: "Collection successfully deleted",
    editCollectionDescription: "Rename or delete the collection",
    collectionEditedSuccessful: "Collection successfully edited",
    collectionEditError: "Something went wrong changing the collection",
    collectionCreateError: "Something went wrong creating the collection",
    confirmUnpairingClient: "Are you sure you want to unpair this client?",
    unpairClientError: "Something went wrong while unpairing the client",
    confirmDeleteExerciseInvite:
      "Are you sure you want to delete the sent exercise?",
    deleteExerciseInviteSuccess:
      "The sent exercise has been deleted successfully",
    deleteExerciseInviteError:
      "Something went wrong while deleting the sent exercise",
    clientGroupAddSuccess: "Client group successfully created",
    clientGroupAddError: "Something went wrong while creating the client group",
    clientGroupDeleteConfirm:
      "Are you sure you want to delete this client group?",
    clientGroupDeleteSuccess: "The client group has been successfully deleted",
    clientGroupDeleteError:
      "Something went wrong while deleting this client group",
    clientGroupDeleteUserConfirm:
      "Are you sure you want to delete this client from the group?",
    clientGroupDeleteUserSuccess:
      "The client has been successfully deleted from the group",
    clientGroupDeleteUserError:
      "Something went wrong while deleting the client from the group",
    autoSignInError: "You could not be logged in again, please try again",
    joinAppForFree: (appName: string) => `Join ${appName} for free`,
    clickToRegisterAsProfessional:
      "Click here to register yourself as a professional!",
    retrieveChallengesError:
      "Something went wrong while retrieving the challenges",
    videoLinkNotSupported: "This video link is not supported",
    loadMapError: "Something went wrong while loading the map",
    noAccessToEnvironment: "You do not have access to this environment",
    itemsFetchError: "Something went wrong fetching new items",
    updateAppNotification: "You probably should update the app",
    enterSearchSpecificResults: "Enter a search for more specific results",
    updateUserError: "Something went wrong updating the user",
    haveFunTrialLicense: "Have fun with your professional trial license!",
    companyProfessional: (companyName: string) => `${companyName} professional`,
    toastErrorHeader: "Oops",
    requestSubmittedDescription:
      "The deletion of your account has been requested. You now have 30 days to undo this, " +
      "after this period your account along with all the related data will be permanently removed " +
      "You can stop this process by logging back into the app before the end date.",
    userAnswerSubmitError: "Something went wrong submitting the answer.",
    userHasNotAnsweredYet: "Please answer the question before submitting.",
    beginQuestionnaireButtonText: `Start ${
      branding.productNames?.questionnaire?.en?.toLowerCase() ?? "questionnaire"
    }`,
    endQuestionnaireButtonText: "Go back to exercise",
    endQuestionnaireText: (questionnaireTitle: string) =>
      `Thank you for completing the ${
        branding.productNames?.questionnaire?.en?.toLowerCase() ??
        "questionnaire"
      } ${questionnaireTitle}`,
    navigateQuestionnaireError: `Something went wrong while navigating to the ${
      branding.productNames?.questionnaire?.en?.toLowerCase() ?? "questionnaire"
    }`,
    enterAnswerPlaceholder: "Enter your answer",
    scheduledAt: (scheduleTimingLabel: string, date: Date) =>
      `${scheduleTimingLabel} starting from ${date.toLocaleDateString(
        '"en-US"',
      )} ${timeFormatter.format(date)}`,
    confirmDeleteScheduledExerciseInvite:
      "Are you sure you want to delete this scheduled exercise invite?",
    deleteScheduledExerciseInviteSuccess:
      "The scheduled exercise has been deleted",
    deleteScheduledExerciseInviteError:
      "Something went wrong while removing the scheduled exercise",
    exerciseInviteScheduledSuccess:
      "Exercise successfully scheduled for client(s)",
    exerciseInviteScheduledError:
      "Something went wrong while scheduling the exercise",
    sessionInviteScheduledSuccess:
      "Session scheduled successfully for client(s)",
    sessionInviteScheduledError:
      "Something went wrong while scheduling the session",
    collectionInviteScheduledSuccess:
      "Collection successfully scheduled for client(s)",
    collectionInviteScheduledError:
      "Something went wrong while scheduling the collection",
    scheduleExerciseInviteToggleLabel: "Schedule this exercise",
    invalidDateEnteredError: "Invalid date entered",
    scheduleTimingDailySelectOption: (time: string) => `Daily at ${time}`,
    scheduleTimingWeeklySelectOption: (nameOfDay: string) =>
      `Weekly on ${nameOfDay}`,
    scheduleTimingMonthlySelectOption: (ordinalDay: string) =>
      `Monthly on the ${ordinalDay}`,
    scheduleTimingOnceTableRowHeader: (date: Date) =>
      `Once on ${date.toLocaleDateString("en-US")} at ${timeFormatter.format(
        date,
      )}`,
    scheduleTimingDailyTableRowHeader: (date: Date) =>
      `Daily at ${timeFormatter.format(date)}`,
    scheduleTimingWeeklyTableRowHeader: (nameOfDay: string, date: Date) =>
      `Weekly on ${nameOfDay} at ${timeFormatter.format(date)}`,
    scheduleTimingMonthlyTableRowHeader: (ordinalDay: string, date: Date) =>
      `Monthly on ${ordinalDay} at ${timeFormatter.format(date)}`,
    multipleScheduleTimingOnceTableRowHeader: (
      scheduledStartDate: Date,
      occurrence: number,
      scheduledEndDate: Date,
    ) =>
      `Once for ${occurrence} times on ${scheduledStartDate.toLocaleDateString(
        "en-US",
      )} between ${timeFormatter.format(
        scheduledStartDate,
      )} and ${timeFormatter.format(scheduledEndDate)}`,
    multipleScheduleTimingDailyTableRowHeader: (
      scheduledStartDate: Date,
      occurrence: number,
      scheduledEndDate: Date,
    ) =>
      `Daily for ${occurrence} times between ${timeFormatter.format(
        scheduledStartDate,
      )} and ${timeFormatter.format(scheduledEndDate)}`,
    multipleScheduleTimingWeeklyTableRowHeader: (
      nameOfDay: string,
      scheduledStartDate: Date,
      occurrence: number,
      scheduledEndDate: Date,
    ) =>
      `Weekly for ${occurrence} times on ${nameOfDay} between ${timeFormatter.format(
        scheduledStartDate,
      )} and ${timeFormatter.format(scheduledEndDate)}`,
    multipleScheduleTimingMonthlyTableRowHeader: (
      ordinalDay: string,
      scheduledStartDate: Date,
      occurrence: number,
      scheduledEndDate: Date,
    ) =>
      `Weekly for ${occurrence} times on ${ordinalDay} between ${timeFormatter.format(
        scheduledStartDate,
      )} and ${timeFormatter.format(scheduledEndDate)}`,
    startsOn: (date: Date) => `Starts on ${timeFormatter.format(date)}`,
    ordinalZeroSuffix: "th",
    ordinalOneSuffix: "st",
    ordinalTwoSuffix: "nd",
    ordinalFewSuffix: "rd",
    ordinalManySuffix: "th",
    ordinalOtherSuffix: "th",
    reportInformation:
      "MindGrapher is designed to help you understand more completely what is going on with your clients day-to-day. It will let you measure how they are doing throughout the day or week and how the interventions you are working on together are affecting their lives.",
    checkOutElearning: (linkToElearningHref: ReactNode) => [
      "Check out the ",
      linkToElearningHref,
      " section to learn more about MindGrapher.",
    ],
    reportDisclaimer:
      "Warning, estimates may not be reliable if there are fewer than ten entries. The more entries, the more reliable the estimates",
    reportDescription: (
      strength: string,
      correlation: number,
      outcomeIdentifier: string,
      processOfChangeIdentifier: string,
    ) =>
      `There is a ${strength.toLowerCase()} correlation ${
        !correlation ? "" : `(${correlation})`
      } between ${outcomeIdentifier} and ${processOfChangeIdentifier}.`,
    noRelationshipDescription: (
      outcomeIdentifier: string,
      processOfChangeIdentifier: string,
    ) =>
      `There is no relationship between ${outcomeIdentifier} and ${processOfChangeIdentifier}`,
    countryLabels: englishCountryLabels,
    timesADayDescription: (hour: string) => `Times between ${hour} and`,
    outcomeTitle: "Outcome or Process You Choose to Use as an Outcome",
    xOutOfY: (x: string, y: string) => `${x} out of ${y}`,
    customizeAssessmentDescription:
      "Customize your assessment by entering a title and description, then select the questions you want to include in your new questionnaire.",
    exerciseCreationError: "Something went wrong while creating the exercise",
  },
  abbreviations: {},
};

const translationsDutch: typeof baseTranslationsEnglish = {
  literals: {
    answers: "Antwoord(en)",
    login: "Inloggen",
    submit: "Verzenden",
    register: "Registreren",
    registration: "Registratie",
    email: "E-mail",
    password: "Wachtwoord",
    forgotPassword: "Wachtwoord vergeten",
    moreInformation: "Meer informatie",
    backToLogin: "Terug naar inloggen",
    send: "Verzenden",
    name: "Naam",
    firstName: "Voornaam",
    lastName: "Achternaam",
    termsOfService: "Gebruiksvoorwaarden",
    connectClient: "Koppel cliënt",
    selectValue: "Selecteer waarde",
    phoneNumber: "Telefoonnummer",
    man: "Man",
    woman: "Vrouw",
    other: "Anders",
    chat: "Chat",
    success: "Gelukt",
    exercise: "Oefening",
    timeline: "Tijdlijn",
    save: "Opslaan",
    cancel: "Annuleren",
    delete: "Verwijderen",
    deleteAccount: "Verwijder account",
    editLocation: "Pas locatie aan",
    setLocation: "Stel locatie in",
    removeLocation: "Verwijder locatie",
    call: "Bel",
    playground: branding.productNames?.playground?.nl ?? "Speelplaats",
    eLearning: "E-learning",
    featured: "Uitgelicht",
    addToCollection: "Toevoegen aan collectie",
    collections: "Collecties",
    edit: "Bewerken",
    street: "Straat",
    houseNumber: "Huisnummer",
    houseNumberAddition: "Huisnummer toevoeging",
    zipCode: "Zipcode",
    countryOfResidence: "Land van verblijf",
    companyNumber: "KVK nummer",
    website: "Website",
    biography: "Biografie",
    address: "Adres",
    unknown: "Onbekend",
    yes: "Ja",
    no: "Nee",
    confirm: "Bevestig",
    view: "Bekijk",
    trainings: branding.productNames?.trainings?.nl ?? "Trainingen",
    freeContent: "Gratis content",
    session: "Sessie",
    sessions: "Sessies",
    exercises: "Exercises",
    message: "Bericht",
    groups: "Groepen",
    note: "Notitie",
    notes: "Notities",
    pairingCode: "Koppelcode",
    pair: "Koppel",
    member: "Lid",
    members: "Leden",
    clients: "Cliënten",
    receivedOn: "Ontvangen op",
    viewedOn: "Gezien op",
    sentOn: "Verzonden op",
    filter: "Filteren",
    categories: "Categorieën",
    labels: "Labels",
    close: "Sluiten",
    filters: "Filters",
    licenseAgreement: "Licentieovereenkomst",
    error: "Fout",
    item: "Item",
    items: "Items",
    selected: "Geselecteerd",
    goToGame: "Ga naar game",
    downloadPdf: "Download PDF",
    goToLink: "Ga naar link",
    previous: "Vorige",
    pairPractitioner: `Koppel ${
      branding.productNames?.practitioner?.nl ?? "Professional"
    }`,
    professionalLicense: "Professional licentie",
    typeToSearch: "Typ hier om te zoeken",
    editPicture: "Wijzig foto",
    logout: "Uitloggen",
    optionSelected: "Optie geselecteerd",
    amountOfClients: "Aantal cliënten",
    visibleOnMap: "Zichtbaar op kaart",
    expirationDateLicense: "Verloopdatum licentie",
    repeatPassword: "Herhaal wachtwoord",
    addClient: "Toevoegen cliënt",
    newPassword: "Nieuw wachtwoord",
    requestSuccessful: "Aanvraag succesvol",
    noUsersSelected: "Geen gebruikers geselecteerd",
    selectUsers: "Selecteer gebruikers",
    developmentEnviroment: "Development omgeving",
    goBack: "Ga terug",
    selectClients: "Selecteer cliënten",
    selectGroups: "Selecteer groepen",
    newClientGroup: "Nieuwe cliënt groep",
    groupName: "Groep naam",
    setPassword: "Stel wachtwoord in",
    unpairClient: "Ontkoppel cliënt",
    selectItems: "Selecteer items",
    noResultsFound: "Geen resultaten gevonden",
    clientsNotFound: "Cliënten niet gevonden",
    clientGroupsNotFound: "Cliënt groepen niet gevonden",
    practitionersNotFound: `${
      branding.productNames?.practitioners?.nl ?? "Professionals"
    } niet gevonden`,
    addClients: "Toevoegen cliënten",
    cityOfResidence: "Woonplaats",
    myPracticeEnvironment: "Mijn oefenomgeving",
    lookOut: "Let op",
    searchByNameAndContent: "Zoek op naam en inhoud",
    enterFirstName: "Typ hier je voornaam",
    noContent: "Geen content",
    newCollection: "Nieuwe collectie",
    noCollectionsFound: "Geen collecties gevonden",
    fillInInvitedClients: "Vul uitgenodigde cliënten in",
    editCollection: "Bewerk collectie",
    collectionName: "Collectie naam",
    myExercises: "Mijn oefeningen",
    onlyMyOwnExercises: "Alleen mijn eigen oefeningen",
    sentExercises: "Verzonden oefeningen",
    requestSubmitted: "Aanvraag ingediend",
    showMore: "Toon meer",
    title: "Titel",
    description: "Beschrijving",
    introduction: "Introductie",
    questionnaires:
      branding.productNames?.questionnaires?.en ?? "Vragenlijsten",
    selectedOption: "Geselecteerde optie",
    next: "Volgende",
    finish: "Afronden",
    completedOn: "Voltooid op",
    goToQuestionnaire: `Ga naar ${
      branding.productNames?.questionnaire?.en?.toLowerCase() ?? "vragenlijst"
    }`,
    notAnsweredYet: "Geen antwoord gegeven",
    schedule: "Inplannen",
    chooseADateAndTime: "Kies een datum en tijd",
    chooseATime: "Kies een tijd",
    once: "Eenmalig",
    multipleTimesADay: "Meerdere keren per dag",
    daily: "Dagelijks",
    weekly: "Wekelijks",
    monthly: "Maandelijks",
    scheduledExercises: "Geplande oefeningen",
    recurrence: "Herhaling",
    selectRecurrence: "Selecteer herhaling",
    licenseExpired: "Licentie verlopen",
    getInTouch: "Neem contact op",
    mindgrapher: "Mindgrapher",
    mindgrapherContent: "Mindgrapher content",
    mindgrapherReport: "Mindgrapher rapport",
    selectVariables: "Selecteer variabelen",
    outcome: "Uitkomst",
    selectOutcome: "Selecteer uitkomst",
    processOfChange: "Veranderingsproces",
    selectProcessOfChange: "Selecteer veranderingsproces",
    plotTrend: "Plot trend",
    verySmallPositiveRelationship: "Zeer kleine positieve relatie",
    verySmallNegativeRelationship: "Zeer kleine negatieve relatie",
    smallPositiveRelationship: "Kleine positieve relatie",
    smallNegativeRelationship: "Kleine negatieve relatie",
    moderatePositiveRelationship: "Gematigde positieve relatie",
    moderateNegativeRelationship: "Gematigde negatieve relatie",
    somewhatStrongPositiveRelationship: "Enigszins sterke positieve relatie",
    somewhatStrongNegativeRelationship: "Enigszins sterke negatieve relatie",
    strongPositiveRelationship: "Sterke positieve relatie",
    strongNegativeRelationship: "Sterke negatieve relatie",
    veryStrongPositiveRelationship: "Zeer sterke positieve relatie",
    veryStrongNegativeRelationship: "Zeer sterke negatieve relatie",
    thereIsNoRelationship: "Er is geen relatie",
    practitioner: branding.productNames?.practitioner?.nl ?? "Professional",
    clearSelection: "Selectie wissen",
    selectHour: "Selecteer uur",
    openQuestion: "Open vraag",
    customizeAssessment: "Pas vragenlijst aan",
  },
  texts: {
    appDescription:
      "In onze app voor professionals staan honderden animaties, oefeningen, games en filmpjes, die je onbeperkt kunt doorsturen naar al je cliënten. Daarnaast krijg je toegang tot live webinars en een wereld aan video-demonstraties en leermodules, die je waardevolle werk nóg veel leuker zullen maken.. Probeer de app nu 30 dagen gratis.",
    createReportError: "Vul alle verplichte velden in",
    wrongLoginCombination: "Verkeerde e-mailadres/wachtwoord combinatie",
    iAgreeToX: (subject: ReactNode) => [
      "Met het aanmaken van een account ga je akkoord met onze ",
      subject,
    ],
    yourLicenseIsExpired: `Je licentie voor ${
      branding.name ?? "PLAPP"
    } is verlopen. Om opnieuw te verlengen neem contact op met`,
    registerError:
      "Er ging iets mis bij het registreren. Probeer eens een ander e-mailadres.",
    forgotPasswordEmailSent:
      "Als bekend, wordt er een mail gestuurd naar het opgegeven e-mailadres",
    passwordResetSuccess:
      "Je wachtwoord is succesvol aangepast! Je kan nu inloggen met je nieuwe wachtwoord.",
    passwordResetError: "Er ging iets mis bij het aanpassen van het wachtwoord",
    passwordResetErrorLong:
      "Er ging iets mis bij het aanpassen van je wachtwoord. Probeer het opnieuw of vraag een nieuwe wachtwoord reset aan.",
    noExercisesReady: "Er staan nog geen oefeningen voor je klaar",
    collectionAddSuccess: "Succesvol toegevoegd aan collectie(s).",
    collectionAddError:
      "Er is iets misgegaan tijdens het toevoegen van de oefening aan de collectie",
    collectionSentError:
      "Er is iets misgegaan tijdens het versturen van de collectie",
    retrievingClientGroupError:
      "Er ging iets mis bij het ophalen van de cliënt groep.",
    addClientsToGroupSuccess: "Cliënten succesvol toegevoegd aan groep.",
    addClientsToGroupError:
      "Er ging iets mis bij het toevoegen van de cliënten.",
    noClientsAddedToGroup:
      "Er zijn nog geen cliënten in deze groep toegevoegd.",
    clientGroupX: (value: string) => `Cliënten ${value}`,
    lastOpenedOnX: (date: Date) =>
      `Laatste keer geopend op: ${date.toLocaleDateString("nl-NL")}`,
    usingStartLicense: "Je maakt nu gebruik van de START licentie.",
    masterOrProLicenseSuggestion: `Wil je ${
      branding.name ?? "PLAP"
    } ook inzetten bij jouw cliënten? Vraag dan een Master of Pro licentie aan!`,
    removeExerciseFromCollectionSuccess: "Oefening verwijderd uit collectie",
    removeExerciseFromCollectionError:
      "Er ging iets mis bij het verwijderen van de oefening uit de collectie",
    noCollectionsCreated:
      "Je hebt nog geen collecties gemaakt. Wil je weten hoe je dit doet? Bekijk dan de onderstaande video.",
    xExercises: (amount?: number) =>
      `${amount} ${amount === 1 ? "oefening" : "oefeningen"}`,
    xSubCategories: (amount: number) =>
      `${amount} ${amount === 1 ? "subcategorie" : "subcategorieën"}`,
    xSessions: (amount: number) =>
      `${amount} ${amount === 1 ? "sessie" : "sessies"}`,
    xItems: (amount: number) => `${amount} ${amount === 1 ? "item" : "items"}`,
    exerciseAddedToCollectionError:
      "Er is iets misgegaan tijdens het toevoegen van de oefening aan de collectie.",
    noteCreationSuccess: "Notitie succesvol aangemaakt",
    noteCreationError: "Er ging iets mis bij het aanmaken van de notitie.",
    noteRemovedSuccess: "Notitie succesvol verwijderd",
    noteRemovedError: "Er ging iets mis bij het verwijderen van de notitie.",
    noteUpdatedSuccess: "Notitie succesvol aangepast",
    noteUpdatedError: "Er ging iets mis bij het aanpassen van de notitie.",
    inviteSentSuccess: "Uitnodiging succesvol",
    inviteSentSuccessLong: "Uitnodiging succesvol naar client verzonden!",
    inviteSentErrorLong:
      "Er is iets misgegaan bij het verzenden van de uitnodiging. Zorg dat de cliënt niet al gekoppeld is aan een andere professional en dat je het maximum aantal cliënten niet hebt overschreden!",
    unknownPairingCode:
      "Jouw koppelcode is helaas onbekend, neem contact op met de helpdesk.",
    yourPairingCodeIsX: (pairingCode: ReactNode) => [
      "Jouw code is ",
      pairingCode,
      ". Geef dit door aan jouw cliënt of koppel direct de cliënt middels onderstaand formulier.",
    ],
    pairingError: "Er ging iets mis bij het koppelen",
    areYouAProfessional: "Ben jij een professional? Klik hier",
    passwordResetSuccessLong:
      "Wachtwoord succesvol aangepast. De cliënt wordt hiervan op de hoogte gebracht per mail.",
    setPasswordForX: (value: string) => `Stel wachtwoord in voor ${value}`,
    setOwnPassword: "Stel je wachtwoord opnieuw in",
    profileUpdatedSuccess: "Profiel succesvol aangepast",
    profileUpdatedError: "Er ging iets mis bij het aanpassen van het profiel",
    deleteAccountPermanently: "Account permanent verwijderen",
    deleteAccountPermanentlyDescription:
      "Vul in het veld hieronder je voornaam in om je account permanent te verwijderen.",
    getUserError: "Er ging iets mis bij het ophalen van de gebruiker",
    locationCreateError: "Er ging iets mis bij het aanmaken van de locatie",
    locationRemoveError: "Er ging iets mis bij het verwijderen van de locatie",
    moreInformation: "Klik hier voor meer informatie.",
    areYouInterested: `Heb je interesse in de ${
      branding.name ?? "PLAP"
    } app voor je team? Neem dan vrijblijvend contact op.`,
    collectionInvitesSentSuccess:
      "Collectie uitnodigingen zijn succesvol verstuurd naar de clienten",
    trialDaysLeft: (days: number) => `Trial ${days.toString()} dagen`,
    companyNameFormal: (companyName: string) => `${companyName} formeel`,
    tellSomethingAboutYourself: "Vertel iets over jezelf",
    passwordsNotIdentical: "De wachtwoorden zijn niet gelijk",
    exerciseInviteSentSuccess:
      "Oefeningen zijn succesvol verstuurd naar de cliënten",
    exerciseInviteSentError:
      "Er is iets misgegaan bij het versturen van de oefeningen",
    collectionAddExerciseSuccess: "Succesvol toegevoegd aan collectie(s)",
    collectionAddExerciseError:
      "Er is iets misgegaan tijdens het toevoegen van de oefening aan de collectie(s)",
    sessionInviteSentSuccess:
      "Sessie(s) zijn succesvol verstuurd naar de cliënten",
    sessionInviteSentError:
      "Er is iets misgegaan bij het versturen van de sessie(s)",
    requestError: "Er is iets verkeerd gegaan",
    confirmLogout: "Weet je zeker dat je wilt uitloggen?",
    logoutError: "Er ging iets mis tijdens het uitloggen",
    editCollectionError: "Er ging iets mis bij het aanpassen van de collectie",
    addExerciseToNewOrExistingCollection:
      "Voeg de oefening toe aan een bestaande collectie, of maak een nieuwe collectie",
    typeMessage: "Typ hier het bericht dat je wilt toevoegen",
    exerciseInviteGroupError:
      "Geen geselecteerde groepen of geen cliënten in geselecteerde groepen",
    typeYourNote: "Typ hier je notitie",
    confirmAgreement: (licenseAgreementHref: ReactNode) => [
      "Door hiet te klikken bevestig je dat je de ",
      licenseAgreementHref,
      " hebt gelezen, begrepen en ermee akkoord gaat",
    ],
    confirmNewsletterSubscription:
      "Ik wil graag op de hoogte gehouden worden en schrijf me in voor de nieuwsbrief",
    confirmRegisterTrial: (trialDays: number) =>
      `${trialDays} dagen vrijblijvend proberen`,
    deleteCollection: "Verwijder collectie",
    confirmCollectionDelete: (confirmText: string, collectionName: string) =>
      `Vul in het veld hieronder het woord "${confirmText}" in om de collectie ${collectionName} definitief te verwijderen`,
    collectionDeleteSuccess: "Collectie succesvol verwijderd",
    editCollectionDescription: "Pas hier de naam van de collectie aan",
    collectionEditedSuccessful: "Collectie succesvol aangepast",
    collectionEditError: "Er ging iets mis bij het aanpassen van de collectie",
    collectionCreateError:
      "Er ging iets mis bij het toevoegen van de collectie",
    confirmUnpairingClient:
      "Weet je zeker dat je deze cliënt wilt ontkoppelen?",
    unpairClientError:
      "Er is iets misgegaan tijdens het ontkoppelen van de cliënt",
    confirmDeleteExerciseInvite:
      "Weet je zeker dat je de verstuurde oefening wilt verwijderen?",
    deleteExerciseInviteSuccess:
      "De verstuurde oefening is succesvol verwijderd",
    deleteExerciseInviteError:
      "Er is iets misgegaan tijdens het verwijderen van de verstuurde oefening",
    clientGroupAddSuccess: "Cliënt groep succesvol aangemaakt",
    clientGroupAddError:
      "Er is iets misgegaan bij het aanmaken van de cliënt groep",
    clientGroupDeleteConfirm:
      "Weet je zeker dat je deze cliënt groep wilt verwijderen?",
    clientGroupDeleteSuccess: "De cliënt groep is succesvol verwijderd",
    clientGroupDeleteError:
      "Er is iets misgegaan bij het verwijderen van de cliënt groep",
    clientGroupDeleteUserConfirm:
      "Weet je zeker dat je deze gebruiker uit de groep wilt verwijderen?",
    clientGroupDeleteUserSuccess: "De gebruiker is succesvol verwijderd",
    clientGroupDeleteUserError:
      "Er is iets misgegaan bij het verwijderen van de gebruiker",
    autoSignInError:
      "Je kon niet opnieuw worden aangemeld, probeer het opnieuw",
    joinAppForFree: (companyName: string) =>
      `Maak gratis kennis met ${companyName}`,
    clickToRegisterAsProfessional:
      "Klik hier om je te registreren als professional!",
    retrieveChallengesError:
      "Er ging iets mis bij het ophalen van de challenges",
    videoLinkNotSupported: "Deze video link wordt niet ondersteund",
    loadMapError: "Er ging iets mis bij het laden van de kaart",
    noAccessToEnvironment: "Je hebt geen toegang tot deze omgeving",
    itemsFetchError: "Er ging iets mis bij het ophalen van de nieuwe items",
    updateAppNotification: "Je moet mogelijk de app updaten",
    enterSearchSpecificResults:
      "Voer een zoekopdracht in voor meer specifieke resultaten",
    updateUserError: "Er ging iets mis bij het updaten van gebruiker",
    haveFunTrialLicense: "Veel plezier met je professional trial licentie!",
    companyProfessional: (companyName: string) => `${companyName} professional`,
    toastErrorHeader: "Oops",
    requestSubmittedDescription:
      "Het verwijderen van je account is aangevraagd. Je hebt vanaf nu 30 dagen om dit ongedaan " +
      "te maken, na deze termijn zal je account samen met alle gerelateerde gegevens permanent " +
      "worden verwijderd. Je kunt dit proces stoppen door vóór de einddatum opnieuw in te loggen in de app.",
    userAnswerSubmitError:
      "Er ging iets mis bij het verzenden van je antwoord.",
    userHasNotAnsweredYet: "Beantwoord de vraag voordat je deze verzendt.",
    beginQuestionnaireButtonText: `Begin met de ${
      branding.productNames?.questionnaire?.nl?.toLowerCase() ?? "vragenlijst"
    }`,
    endQuestionnaireButtonText: "Ga terug naar de oefening",
    endQuestionnaireText: (questionnaireTitle: string) =>
      `Bedankt voor het invullen van de ${
        branding.productNames?.questionnaire?.nl?.toLowerCase() ?? "vragenlijst"
      } ${questionnaireTitle}`,
    navigateQuestionnaireError: `Er is iets misgegaan tijdens het navigeren naar de ${
      branding.productNames?.questionnaire?.nl?.toLowerCase() ?? "vragenlijst"
    }`,
    enterAnswerPlaceholder: "Vul je antwoord hier in",
    scheduledAt: (scheduleTimingLabel: string, date: Date) =>
      `${scheduleTimingLabel} beginnend vanaf ${timeFormatter.format(date)}`,
    deleteScheduledExerciseInviteSuccess:
      "De ingeplande oefening is succesvol verwijderd",
    deleteScheduledExerciseInviteError:
      "Er is iets misgegaan bij het verwijderen van ingeplande oefening",
    confirmDeleteScheduledExerciseInvite:
      "Weet je zeker dat je deze geplande oefening wilt verwijderen?",
    exerciseInviteScheduledSuccess:
      "Oefening succesvol ingepland voor cliënt(en)",
    exerciseInviteScheduledError:
      "Er ging iets mis bij het inplannen van de oefeningen",
    sessionInviteScheduledSuccess: "Sessie succesvol ingepland voor cliënt(en)",
    sessionInviteScheduledError:
      "Er ging iets mis bij het inplannen van de sessie",
    collectionInviteScheduledSuccess:
      "Collectie succesvol ingepland voor cliënt(en)",
    collectionInviteScheduledError:
      "Er ging iets mis bij het inplannen van de collectie",
    scheduleExerciseInviteToggleLabel: "Plan de oefening in",
    invalidDateEnteredError: "Ongeldige datum ingevoerd",
    scheduleTimingDailySelectOption: (time: string) => `Dagelijks om ${time}`,
    scheduleTimingWeeklySelectOption: (nameOfDay: string) =>
      `Wekelijks op ${nameOfDay}`,
    scheduleTimingMonthlySelectOption: (ordinalDay: string) =>
      `Maandelijks op de ${ordinalDay}`,
    scheduleTimingOnceTableRowHeader: (date: Date) =>
      `Eenmalig op ${date.toLocaleDateString(
        "nl-NL",
      )} om ${timeFormatter.format(date)}`,
    scheduleTimingDailyTableRowHeader: (date: Date) =>
      `Dagelijks om ${date.toLocaleTimeString("nl-NL")}`,
    scheduleTimingWeeklyTableRowHeader: (nameOfDay: string, date: Date) =>
      `Wekelijks op ${nameOfDay} om ${timeFormatter.format(date)}`,
    scheduleTimingMonthlyTableRowHeader: (ordinalDay: string, date: Date) =>
      `Maandelijks op de ${ordinalDay} om ${timeFormatter.format(date)}`,
    multipleScheduleTimingOnceTableRowHeader: (
      scheduledStartDate: Date,
      occurrence: number,
      scheduledEndDate: Date,
    ) =>
      `Eenmalig voor ${occurrence} keer op ${scheduledStartDate.toLocaleDateString(
        "nl-NL",
      )} tussen ${timeFormatter.format(
        scheduledStartDate,
      )} en ${timeFormatter.format(scheduledEndDate)}`,
    multipleScheduleTimingDailyTableRowHeader: (
      scheduledStartDate: Date,
      occurrence: number,
      scheduledEndDate: Date,
    ) =>
      `Dagelijks voor ${occurrence} keer, tussen ${timeFormatter.format(
        scheduledStartDate,
      )} en ${timeFormatter.format(scheduledEndDate)}`,
    multipleScheduleTimingWeeklyTableRowHeader: (
      nameOfDay: string,
      scheduledStartDate: Date,
      occurrence: number,
      scheduledEndDate: Date,
    ) =>
      `Wekelijks voor ${occurrence} keer op ${nameOfDay} tussen ${timeFormatter.format(
        scheduledStartDate,
      )} en ${timeFormatter.format(scheduledEndDate)}`,
    multipleScheduleTimingMonthlyTableRowHeader: (
      ordinalDay: string,
      scheduledStartDate: Date,
      occurrence: number,
      scheduledEndDate: Date,
    ) =>
      `Wekelijks voor ${occurrence} keer op de ${ordinalDay} tussen ${timeFormatter.format(
        scheduledStartDate,
      )} en ${timeFormatter.format(scheduledEndDate)}`,
    startsOn: (date: Date) => `Begint op ${timeFormatter.format(date)}`,
    ordinalZeroSuffix: "e",
    ordinalOneSuffix: "e",
    ordinalTwoSuffix: "e",
    ordinalFewSuffix: "e",
    ordinalManySuffix: "e",
    ordinalOtherSuffix: "e",
    reportInformation:
      "MindGrapher is ontworpen om je te helpen je cliënten beter te begrijpen. Het helpt je om te meten hoe het met ze gaat gedurende de dag of week en hoe de interventies die je samen met ze doet hun leven beïnvloeden.",
    checkOutElearning: (linkToElearningHref: ReactNode) => [
      "Bekijk de ",
      linkToElearningHref,
      " sectie om meer te leren over MindGrapher.",
    ],
    reportDisclaimer:
      "Let op, schattingen kunnen onbetrouwbaar zijn als er minder dan tien inzendingen zijn. Hoe meer inzendingen, hoe betrouwbaarder de schattingen.",
    reportDescription: (
      strength: string,
      correlation: number,
      outcomeIdentifier: string,
      processOfChangeIdentifier: string,
    ) =>
      `Er is een ${strength.toLowerCase()} correlatie ${
        !correlation ? "" : `(${correlation})`
      } tussen ${outcomeIdentifier} en ${processOfChangeIdentifier}.`,
    noRelationshipDescription: (
      outcomeIdentifier: string,
      processOfChangeIdentifier: string,
    ) =>
      `Er is geen relatie tussen ${outcomeIdentifier} en ${processOfChangeIdentifier}`,
    countryLabels: dutchCountryLabels,
    timesADayDescription: (hour: string) => `Keer tussen ${hour} en`,
    outcomeTitle: "Uitkomst of proces dat je kiest als uitkomst",
    xOutOfY: (x: string, y: string) => `${x} van de ${y}`,
    customizeAssessmentDescription:
      "Pas je vragenlijst aan door een titel en beschrijving in te voeren, en selecteer de vragen die je in je nieuwe vragenlijst wilt opnemen.",
    exerciseCreationError: "Er is iets misgegaan bij het maken van de oefening",
  },
  abbreviations: {},
};

type Translations = {
  [countryCode: string]: typeof baseTranslationsEnglish;
};

const translations: Translations = {
  "en-GB": baseTranslationsEnglish,
  "en-US": baseTranslationsEnglish,
  "nl-NL": translationsDutch,
};

const envLocale = environment.locale;
const dictionary = translations[envLocale] ?? baseTranslationsEnglish;
export { dictionary };

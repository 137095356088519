import { LicenseType, Role } from "@hulanbv/platformapp";
import { ChatScreen } from "../../../components/screens/chat.screen";
import { ClientGroupMembersScreen } from "../../../components/screens/client-group-members.screen";
import { ClientProfileScreen } from "../../../components/screens/client-profile.screen";
import { ClientsScreen } from "../../../components/screens/clients.screen";
import { CollectionsScreen } from "../../../components/screens/collections.screen";
import { ElearningScreen } from "../../../components/screens/elearning.screen";
import { ExerciseScreen } from "../../../components/screens/exercise.screen";
import { ForgotPasswordResetScreen } from "../../../components/screens/forgot-password-reset.screen";
import { ForgotPasswordScreen } from "../../../components/screens/forgot-password.screen";
import { HomeClientScreen } from "../../../components/screens/home/home-client.screen";
import { HomeProfessionalScreen } from "../../../components/screens/home/home-professional.screen";
import { LoginScreen } from "../../../components/screens/login.screen";
import { MyProfileScreen } from "../../../components/screens/my-profile.screen";
import { NoteCreateScreen } from "../../../components/screens/note-create.screen";
import { NoteEditScreen } from "../../../components/screens/note-edit.screen";
import { PairClientScreen } from "../../../components/screens/pair-client.screen";
import { PairPractitionerScreen } from "../../../components/screens/pair-practitioner.screen";
import { PlaygroundScreen } from "../../../components/screens/playground.screen";
import { PractitionerSearchScreen } from "../../../components/screens/practitioner-search.screen";
import { ProfileEditClientPasswordScreen } from "../../../components/screens/profile-edit-client-password.screen";
import { ProfileEditScreen } from "../../../components/screens/profile-edit.screen";
import { ProfileResetPasswordScreen } from "../../../components/screens/profile-reset-password.screen";
import { ProfileSetLocationScreen } from "../../../components/screens/profile-set-location.screen";
import { ProfileScreen } from "../../../components/screens/profile.screen";
import { RedirectScreen } from "../../../components/screens/redirect.screen";
import { RegisterPractitionerScreen } from "../../../components/screens/register-practitioner.screen";
import { RegisterScreen } from "../../../components/screens/register.screen";
import { SendCollectionInvitesScreen } from "../../../components/screens/send-collection-invites.screen";
import { SendExerciseInvitesScreen } from "../../../components/screens/send-exercise-invites.screen";
import { SendSessionInvitesScreen } from "../../../components/screens/send-session-invites.screen";
import { TrainingSessionScreen } from "../../../components/screens/training-session.screen";
import { TemplateScreen } from "../../../components/screens/template.screen";
import { TimelineScreen } from "../../../components/screens/timeline.screen";
import { TrainingScreen } from "../../../components/screens/training.screen";
import { authenticationService } from "../../authentication/authentication.service";
import { IRoute } from "../interfaces/route.interface";
import { QuestionnaireScreen } from "../../../components/screens/questionnaire.screen";
import { QuestionnaireResultsScreen } from "../../../components/screens/questionnaire-results.screen";
import { QuestionnaireIntroScreen } from "../../../components/screens/questionnaire-intro.screen";
import { QuestionnaireEndScreen } from "../../../components/screens/questionnaire-end.screen";
import { CreateReportScreen } from "../../../components/screens/create-report.screen";
import { WeekResultsReportScreen } from "../../../components/screens/week-results-report.screen";
import { branding } from "../../../constants/branding.constants";
import { QuestionnaireOverviewScreen } from "../../../components/screens/questionnaire-overview.screen";
import { ClientIntroductionContentScreen } from "../../../components/screens/client-introduction-content.screen";
import { MindgrapherContentScreen } from "../../../components/screens/mindgrapher-content.screen";

export const routes: Record<string, IRoute> = {
  // unauthorized routes
  template: {
    path: "/template",
    component: TemplateScreen,
    isAccessible: () => !authenticationService.getSession(),
  },
  login: {
    path: "/",
    component: LoginScreen,
    isAccessible: () => !authenticationService.getSession(),
  },
  register: {
    path: "/register",
    component: RegisterScreen,
    isAccessible: () => !authenticationService.getSession(),
  },
  registerPractitioner: {
    path: "/register-practitioner",
    component: RegisterPractitionerScreen,
    isAccessible: () =>
      authenticationService.getSession()?.user?.role === Role.USER,
  },
  forgotPassword: {
    path: "/forgot-password",
    component: ForgotPasswordScreen,
    isAccessible: () => !authenticationService.getSession(),
  },
  resetPassword: {
    path: "/forgot-password-reset/user/:userId/token/:token",
    component: ForgotPasswordResetScreen,
  },
  clients: {
    path: "/clients",
    component: ClientsScreen,
    isAccessible: () => {
      const session = authenticationService.getSession();
      return session?.user?.role === Role.PRACTITIONER;
    },
  },
  clientGroupMembers: {
    path: "/client-groups/:clientGroupId",
    component: ClientGroupMembersScreen,
    isAccessible: () =>
      authenticationService.getSession()?.user?.role === Role.PRACTITIONER,
  },
  clientProfile: {
    path: "/clients/:userId/:tab?",
    component: ClientProfileScreen,
    isAccessible: () => {
      const session = authenticationService.getSession();
      const isPractitioner = session?.user?.role === Role.PRACTITIONER;
      const hasValidLicense =
        !!session?.user?.license &&
        [
          LicenseType.BUSINESS,
          LicenseType.MASTER,
          LicenseType.IN_TRAINING,
          LicenseType.BUSINESS_IN_TRAINING,
          LicenseType.PRO,
        ].includes(session.user.license.type);

      // only practitioners without a pro license can have access to client profiles
      return isPractitioner && hasValidLicense;
    },
  },
  // start home-routes
  homeProfessional: {
    path: "/",
    component: HomeProfessionalScreen,
    isAccessible: () => {
      const user = authenticationService.getSession()?.user;
      return user?.role === Role.PRACTITIONER;
    },
  },
  homeClient: {
    path: "/",
    component: HomeClientScreen,
    isAccessible: () => {
      const user = authenticationService.getSession()?.user;
      return user?.role === Role.USER;
    },
  },
  // end home-routes
  weekResultsReportCreate: {
    path: "/clients/:userId/user-answers/create",
    component: CreateReportScreen,
    isAccessible: () =>
      branding.features?.hasWeekResultReports === true &&
      authenticationService.getSession()?.user?.role === Role.PRACTITIONER,
  },
  weekResultsReport: {
    path: "/clients/:userId/user-answers/week-results?resultIdentifiers[]=:resultIdentifier1&resultIdentifiers[]=:resultIdentifier2",
    component: WeekResultsReportScreen,
    isAccessible: () =>
      branding.features?.hasWeekResultReports === true &&
      authenticationService.getSession()?.user?.role === Role.PRACTITIONER,
  },
  noteCreate: {
    path: "/clients/:userId/notes/create",
    component: NoteCreateScreen,
    isAccessible: () =>
      authenticationService.getSession()?.user?.role === Role.PRACTITIONER,
  },
  noteEdit: {
    path: "/clients/notes/create/:noteId",
    component: NoteEditScreen,
    isAccessible: () =>
      authenticationService.getSession()?.user?.role === Role.PRACTITIONER,
  },
  search: {
    path: "/practitioner-search/:tab?",
    component: PractitionerSearchScreen,
    isAccessible: () => {
      const user = authenticationService.getSession()?.user;
      // only accessible if no practitionerId paired and user role is role.user
      return user?.role === Role.USER && !user.practitioner;
    },
  },
  collections: {
    path: "/collections",
    component: CollectionsScreen,
    isAccessible: () => !!authenticationService.getSession(),
  },
  chat: {
    path: "/chat",
    component: ChatScreen,
    isAccessible: () => !!authenticationService.getSession(),
  },
  exercise: {
    path: "/exercise/:exerciseId",
    component: ExerciseScreen,
    isAccessible: () => !!authenticationService.getSession(),
  },
  exerciseInvite: {
    path: "/exercise-invite/:exerciseInviteId",
    component: ExerciseScreen,
    isAccessible: () => !!authenticationService.getSession(),
  },
  editProfile: {
    path: "/profile/edit",
    component: ProfileEditScreen,
    isAccessible: () => !!authenticationService.getSession(),
  },
  editLocation: {
    path: "/profile/set-location",
    component: ProfileSetLocationScreen,
    isAccessible: () =>
      authenticationService.getSession()?.user?.role === Role.PRACTITIONER &&
      branding.features?.isMapEnabled === true,
  },
  elearning: {
    path: "/elearning",
    component: ElearningScreen,
    isAccessible: () =>
      authenticationService.getSession()?.user?.role === Role.PRACTITIONER,
  },
  myProfileEditPassword: {
    path: "/profile/edit-password",
    component: ProfileResetPasswordScreen,
    isAccessible: () => !!authenticationService.getSession(),
  },
  profile: {
    path: "/profile/:userId",
    component: ProfileScreen,
    isAccessible: () => !!authenticationService.getSession(),
  },
  profileEditPassword: {
    path: "/profile/:userId/set-password",
    component: ProfileEditClientPasswordScreen,
    isAccessible: () =>
      authenticationService.getSession()?.user?.role === Role.PRACTITIONER,
  },
  myProfile: {
    path: "/profile",
    component: MyProfileScreen,
    isAccessible: () => !!authenticationService.getSession(),
  },

  pairClient: {
    path: "/pair-client",
    component: PairClientScreen,
    isAccessible: () => {
      const session = authenticationService.getSession();
      const isPractitioner = session?.user?.role === Role.PRACTITIONER;
      const hasValidLicense =
        !!session?.user?.license &&
        [
          LicenseType.BUSINESS,
          LicenseType.MASTER,
          LicenseType.IN_TRAINING,
          LicenseType.BUSINESS_IN_TRAINING,
          LicenseType.PRO,
        ].includes(session.user.license.type);

      // only practitioners without a personal license can add clients
      return isPractitioner && hasValidLicense;
    },
  },
  pairPractitioner: {
    path: "/pair-practitioner",
    component: PairPractitionerScreen,
    isAccessible: () => {
      const user = authenticationService.getSession()?.user;
      // only accessible if no practitionerId paired and user role is role.user
      return user?.role === Role.USER && !user.practitioner;
    },
  },
  playground: {
    path: "/playground",
    component: PlaygroundScreen,
    isAccessible: () =>
      authenticationService.getSession()?.user?.role === Role.PRACTITIONER,
  },
  mindgrapherContent: {
    path: "/mindgrapher-content",
    component: MindgrapherContentScreen,
    isAccessible: () =>
      branding.features?.hasMindgrapherContent === true &&
      authenticationService.getSession()?.user?.role === Role.PRACTITIONER,
  },
  questionnaireOverview: {
    path: "/exercise/:exerciseId/questionnaire/:questionnaireId/overview",
    component: QuestionnaireOverviewScreen,
    isAccessible: () =>
      authenticationService.getSession()?.user?.role === Role.PRACTITIONER,
  },
  questionnaireIntro: {
    path: "/exercise-invite/:exerciseInviteId/questionnaire/:questionnaireId/intro",
    component: QuestionnaireIntroScreen,
    isAccessible: () => !!authenticationService.getSession(),
  },
  questionnaireQuestions: {
    path: "/exercise-invite/:exerciseInviteId/questionnaire/:questionnaireId/questions",
    component: QuestionnaireScreen,
    isAccessible: () => !!authenticationService.getSession(),
  },
  questionnaireEnd: {
    path: "/exercise-invite/:exerciseInviteId/questionnaire/:questionnaireId/end",
    component: QuestionnaireEndScreen,
    isAccessible: () => !!authenticationService.getSession(),
  },
  questionnaireResults: {
    path: "/clients/:userId/exercise-invite/:exerciseInviteId/questionnaire/:questionnaireId/results",
    component: QuestionnaireResultsScreen,
    isAccessible: () =>
      authenticationService.getSession()?.user?.role === Role.PRACTITIONER,
  },
  sendExerciseInvites: {
    path: "/send-exercise-invites/:exerciseId",
    component: SendExerciseInvitesScreen,
    isAccessible: () => {
      const session = authenticationService.getSession();
      const isPractitioner = session?.user?.role === Role.PRACTITIONER;
      const hasValidLicense =
        !!session?.user?.license &&
        [
          LicenseType.BUSINESS,
          LicenseType.MASTER,
          LicenseType.IN_TRAINING,
          LicenseType.BUSINESS_IN_TRAINING,
          LicenseType.PRO,
        ].includes(session.user.license.type);

      // only practitioners without a personal license can have access to client profiles
      return isPractitioner && hasValidLicense;
    },
  },
  sendSessionInvites: {
    path: "/send-session-invites/:sessionId",
    component: SendSessionInvitesScreen,
    isAccessible: () => {
      const session = authenticationService.getSession();
      const isPractitioner = session?.user?.role === Role.PRACTITIONER;
      const hasValidLicense =
        !!session?.user?.license &&
        [
          LicenseType.BUSINESS,
          LicenseType.MASTER,
          LicenseType.IN_TRAINING,
          LicenseType.BUSINESS_IN_TRAINING,
          LicenseType.PRO,
        ].includes(session.user.license.type);

      // only practitioners without a personal license can have access to client profiles
      return isPractitioner && hasValidLicense;
    },
  },
  sendcollectionInvites: {
    path: "/send-collection-invites/:collectionId",
    component: SendCollectionInvitesScreen,
    isAccessible: () => {
      const session = authenticationService.getSession();
      const isPractitioner = session?.user?.role === Role.PRACTITIONER;
      const hasValidLicense =
        !!session?.user?.license &&
        [
          LicenseType.BUSINESS,
          LicenseType.MASTER,
          LicenseType.IN_TRAINING,
          LicenseType.BUSINESS_IN_TRAINING,
          LicenseType.PRO,
        ].includes(session.user.license.type);

      // only practitioners without a personal license can send collection invites
      return isPractitioner && hasValidLicense;
    },
  },
  session: {
    path: "/trainings/:trainingId/sessions/:sessionId",
    component: TrainingSessionScreen,
    isAccessible: () => !!authenticationService.getSession(),
  },
  timeline: {
    path: "/timeline",
    component: TimelineScreen,
    isAccessible: () =>
      authenticationService.getSession()?.user?.role === Role.USER,
  },
  trainings: {
    path: "/trainings",
    component: TrainingScreen,
    isAccessible: () => {
      const user = authenticationService.getSession()?.user;
      return user?.role === Role.PRACTITIONER;
    },
  },
  clientIntroductionContent: {
    path: "/client-introduction-content",
    component: ClientIntroductionContentScreen,
    isAccessible: () =>
      branding.features?.hasIntroductionContent === true &&
      authenticationService.getSession()?.user?.role === Role.USER,
  },
  notFound: {
    path: "",
    component: RedirectScreen,
  },
};

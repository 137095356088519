import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router";
import { style } from "typestyle";
import { dictionary } from "../../state/common/constants/dictionary.constants";
import { Header } from "../elements/header.element";
import { PageBody } from "../elements/page-body.element";
import { Page } from "../elements/page.element";
import { userAnswerService } from "../../state/user-answer/user-answer.service";
import { Description } from "../elements/description.element";
import { Flex } from "../elements/flex.element";
import { ReactComponent as InfoSvg } from "../../assets/graphics/symbols/info.svg";
import { WeekNavigationElement } from "../elements/week-navigation.element";
import { calculateCorrelationCoefficient } from "../../utils/calculate-correlation-coefficient.utils";
import { localStorageUtils } from "../../utils/local-storage.utils";
import { useModalContext } from "../../state/common/contexts/modal.context";
import { branding } from "../../constants/branding.constants";
import { VideoPlayerModal } from "../templates/modals/video-player-modal.template";
import { SvgIcon } from "../elements/svg-icon.element";
import { routes } from "../../state/common/constants/routes.constants";
import { BarChart } from "../elements/bar-chart.element";

// Variable that holds the range of the relationship and the label that should.
// be displayed for that range. The ranges are inclusive. The first number is
// the lower bound and the second number is the upper bound.
const relationshipLabels: [range: [number, number], label: string][] = [
  [[0.0001, 0.05], `${dictionary.literals.verySmallPositiveRelationship}`],
  [[0, -0.05], `${dictionary.literals.verySmallNegativeRelationship}`],
  [[0.06, 0.1], `${dictionary.literals.smallPositiveRelationship}`],
  [[-0.06, -0.1], `${dictionary.literals.smallNegativeRelationship}`],
  [[0.11, 0.2], `${dictionary.literals.moderatePositiveRelationship}`],
  [[-0.11, -0.2], `${dictionary.literals.moderateNegativeRelationship}`],
  [[0.21, 0.3], `${dictionary.literals.somewhatStrongPositiveRelationship}`],
  [[-0.21, -0.3], `${dictionary.literals.somewhatStrongNegativeRelationship}`],
  [[0.31, 0.4], `${dictionary.literals.strongPositiveRelationship}`],
  [[-0.31, -0.4], `${dictionary.literals.strongNegativeRelationship}`],
  [[0.41, 1], `${dictionary.literals.veryStrongPositiveRelationship}`],
  [[-0.41, -1], `${dictionary.literals.veryStrongNegativeRelationship}`],
];

export const WeekResultsReportScreen: FC = () => {
  const { openModal } = useModalContext();
  const history = useHistory();

  const params = useParams<{
    userId: string;
    resultIdentifier1: string;
    resultIdentifier2: string;
  }>();

  const [outcomeResults, setOutcomeResults] = useState<string[]>([]);
  const [processOfChangeResults, setProcessOfChangeResults] = useState<
    string[]
  >([]);

  const relationshipReport = useMemo(() => {
    const correlation = calculateCorrelationCoefficient(
      outcomeResults.map((value) => Number(value)),
      processOfChangeResults.map((value) => Number(value)),
    );
    const label = relationshipLabels.find(
      ([range]) => correlation >= range[0] && correlation <= range[1],
    )?.[1];

    if (!label) {
      return dictionary.texts.noRelationshipDescription(
        params.resultIdentifier1,
        params.resultIdentifier2,
      );
    }

    return dictionary.texts.reportDescription(
      label,
      correlation,
      params.resultIdentifier1,
      params.resultIdentifier2,
    );
  }, [
    outcomeResults,
    params.resultIdentifier1,
    params.resultIdentifier2,
    processOfChangeResults,
  ]);

  const [targetDate, setTargetDate] = useState<Date>(new Date());

  const xAxisLabels = useMemo(() => {
    const labels = [];
    for (let i = 13; i >= 0; i -= 1) {
      const date = new Date(targetDate);
      date.setDate(date.getDate() - i);

      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const formattedDate = `${day}-${month}`;

      labels.push(formattedDate);
    }
    return labels;
  }, [targetDate]);

  const handleInformationClick = useCallback(() => {
    // Showing the instructions video.
    openModal(() => (
      <VideoPlayerModal url={branding.videoUrls?.explanationOfGraph ?? ""} />
    ));
  }, [openModal]);

  useEffect(() => {
    (async () => {
      const { data: weekResultsOutcome } =
        await userAnswerService.getWeekResults(
          targetDate,
          params.resultIdentifier1,
          {
            match: {
              userId: params.userId,
            },
          },
        );
      const { data: weekResultsProcessOfChange } =
        await userAnswerService.getWeekResults(
          targetDate,
          params.resultIdentifier2,
          {
            match: {
              userId: params.userId,
            },
          },
        );

      setOutcomeResults(weekResultsOutcome);
      setProcessOfChangeResults(weekResultsProcessOfChange);
    })();
  }, [
    params.resultIdentifier1,
    params.resultIdentifier2,
    params.userId,
    targetDate,
  ]);

  useEffect(() => {
    if (!branding.videoUrls?.explanationOfGraph) {
      return;
    }
    // Checking if the user has seen the instructions video before.
    const hasSeenReportInstructionsVideo = localStorageUtils.getItem(
      "hasSeenReportInstructionsVideo",
    );

    // If the user has seen the instructions video before, then we return and
    // don't show the instructions video again.
    if (hasSeenReportInstructionsVideo) {
      return;
    }

    (async () => {
      // Showing the instructions video.
      await openModal(() => (
        <VideoPlayerModal url={branding.videoUrls?.explanationOfGraph ?? ""} />
      ));
    })();

    // Setting hasSeenReportInstructionsVideo to true so that the user doesn't
    // see the instructions video again.
    localStorageUtils.setItem("hasSeenReportInstructionsVideo", true);
  }, [
    openModal,
    params.resultIdentifier1,
    params.resultIdentifier2,
    params.userId,
  ]);

  return (
    <Page>
      <PageBody fullHeight>
        <Flex gap={5}>
          <Header className={styles.centerHeader}>
            {dictionary.literals.mindgrapherReport}
          </Header>
          {branding.videoUrls?.explanationOfGraph && (
            <SvgIcon
              attributes={{
                style: {
                  cursor: "pointer",
                },
                onClick: handleInformationClick,
              }}
              icon={InfoSvg}
              width={20}
              height={20}
            />
          )}
        </Flex>
        <Flex
          flex={1}
          direction="column"
          justifyContent="flex-start"
          minHeight={"100%"}
        >
          <Flex
            direction="column"
            gap={0}
            maxWidth={500}
            style={{
              minWidth: "100%",
            }}
          >
            <WeekNavigationElement
              defaultEndDate={targetDate}
              onChange={setTargetDate}
            />
            <BarChart
              xLabels={xAxisLabels}
              data={[
                {
                  color: "rgb(32,32,237)",
                  label: params.resultIdentifier1,
                  values: outcomeResults.map((value) => Number(value)),
                },
                {
                  color: "rgb(255,101,0)",
                  label: params.resultIdentifier2,
                  values: processOfChangeResults.map((value) => Number(value)),
                },
              ]}
            />
          </Flex>

          <Description hasNoBorder>{relationshipReport}</Description>
          <Description>{dictionary.texts.reportDisclaimer}</Description>
          <Description hasNoBorder>
            {dictionary.texts.reportInformation}
          </Description>
          <Description hasNoBorder>
            {dictionary.texts.checkOutElearning(
              <span
                onClick={() => history.replace(routes.elearning.path)}
                style={{ cursor: "pointer", textDecoration: "underline" }}
                key={0}
              >
                {dictionary.literals.eLearning.toLowerCase()}
              </span>,
            )}
          </Description>
        </Flex>
      </PageBody>
    </Page>
  );
};

const styles = {
  centerHeader: style({
    display: "flex",
    justifyContent: "center",
  }),
};
